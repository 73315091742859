// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.outlined-button {
  padding: 8px 32px;
  border-radius: 8px;
  border: 1px solid #007C6E;
  background: #fff;
  color: #007C6E;
  font-family: Mulish, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/components/Buttons/OutlinedButton/OutlinedButton.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,kBAAA;EACA,yBAAA;EACA,gBAAA;EACA,cAAA;EACA,+BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AACF","sourcesContent":[".outlined-button {\n  padding: 8px 32px;\n  border-radius: 8px;\n  border: 1px solid  #007C6E;\n  background: #fff;\n  color: #007C6E;\n  font-family: Mulish, sans-serif;\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n  text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
