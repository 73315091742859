import { backend } from './../index';

export const getCities = async data => {
  try {
    const response = await backend.get('/cities/', { params: data });
    return response;
  } catch (error) {
    console.error('Error fetching cities:', error);
    throw error;
  }
};

export const getCityById = async id => {
  try {
    const response = await backend.get(`/cities/${id}/`);
    return response;
  } catch (error) {
    console.error('Error fetching city by id:', error);
    throw error;
  }
};
