import React from 'react';
import './Headlines.scss';
import { colors } from '../Colors/Colors';

export const Headline1 = ({ children, variant }) => {
  return (
    <h1
      className="Headline h1"
      style={{ color: colors[variant] || colors.black }}
    >
      {children}
    </h1>
  );
};

export const Headline2 = ({ children, variant, textAlign, className }) => {
  return (
    <h2
      className={`Headline h2 ${className}`}
      style={{
        color: colors[variant] || colors.black,
        textAlign: textAlign || 'start',
      }}
    >
      {children}
    </h2>
  );
};

export const Headline3 = ({ children, variant }) => {
  return (
    <h3
      className="Headline h3"
      style={{ color: colors[variant] || colors.black }}
    >
      {children}
    </h3>
  );
};

export const Headline4 = ({ children, variant, textAlign }) => {
  return (
    <h4
      className="Headline h4"
      style={{
        color: colors[variant] || colors.black,
        textAlign: textAlign || 'left',
      }}
    >
      {children}
    </h4>
  );
};

export const Headline5 = ({ children, variant }) => {
  return (
    <h5
      className="Headline h5"
      style={{ color: colors[variant] || colors.black }}
    >
      {children}
    </h5>
  );
};

export const Headline6 = ({ children, variant }) => {
  return (
    <h6
      className="Headline h6"
      style={{ color: colors[variant] || colors.black }}
    >
      {children}
    </h6>
  );
};
