import {
  Headline2,
  Headline3,
} from '../../components/Text/Headlines/Headlines';
import {
  ButtonLText,
  ButtonMText,
  DetailsText,
  MainText,
} from '../../components/Text/Body/Bodies';
import './TourForm.scss';
import * as Yup from 'yup';
import { parse } from 'date-fns';
import { useParams } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { Modal, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { InfoCircle, NotificationBing, TickCircle } from 'iconsax-react';
import { getCities } from '../../api/Cities/Cities.api';
import DateField from './components/DateField/DateField';
import { personCountState } from '../../recoil/Main/Main';
import { userState } from '../../store/users/users.store';
import { getAllStates } from '../../api/States/States.api';
import InputField from './components/InputField/InputField';
import InputDrop from '../../components/InputDrop/InputDrop';
import { loginModal } from '../../store/modals/modals.store';
import DateToDate from '../../components/DateToDate/DateToDate';
import SwitchField from './components/CheckboxField/SwitchField';
import { getCountries } from '../../api/Countries/Countries.api';
import { patchUserData } from '../../api/UserDatas/UserDatas.api';
import { ButtonL, ButtonM } from '../../components/Buttons/Buttons';
import { getTShirtSizes } from '../../api/T-ShirtSizes/T-ShirtSizes.api';
import { createReservation } from '../../api/Reservation/Reservation.api';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { getClothingSizes } from '../../api/ClothingSizes/ClothingSizes.api';
import { getScheduleById } from '../../api/SchedulerOfTours/SchedulerOfTours.api';

const TourForm = () => {
  const { id } = useParams();
  const [tour, setTour] = useState({});
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [countries, setCountries] = useState(null);
  const [User, setUser] = useRecoilState(userState);
  const [tShirtSizes, setTShirtSizes] = useState([]);
  const personCount = useRecoilValue(personCountState);
  const [clothingSizes, setClothingSizes] = useState([]);
  const [successModal, setSuccessModal] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const setLoginModalState = useSetRecoilState(loginModal);

  const getChangedFields = (initialValues, values) => {
    const fieldsToCheck = [
      'address',
      'city',
      'clothing_size',
      'country',
      'date_of_birth',
      'email',
      'first_name',
      'last_name',
      'phone_number',
      'state',
      'zip_code',
      'height',
      't_shirt_size',
      'work_for',
    ];

    const changedFields = {};

    fieldsToCheck.forEach(key => {
      const initialValue = initialValues[key];
      const currentValue = values[key];

      if (key === 'date_of_birth') {
        const initialDate = new Date(initialValue).getTime();
        const currentDate = new Date(currentValue).getTime();
        if (initialDate !== currentDate) {
          changedFields[key] = currentValue;
        }
      } else if (initialValue !== currentValue) {
        changedFields[key] = currentValue;
      }
    });

    return changedFields;
  };

  const handleSubmit = async (values, { setErrors, resetForm }) => {
    try {
      // Вызываем patchUserData для обновления данных пользователя
      const changedFields = getChangedFields(initialValues, values);

      if (Object.keys(changedFields).length !== 0) {
        await patchUserData(User?.id, changedFields, setUser);
      }

      // После успешного обновления данных пользователя создаём резервацию
      await createReservation({
        travel_partner: values?.travel_partner,
        message: values?.message,
        is_trip_protection: values?.is_trip_protection,
        is_newsletter: values?.is_newsletter,
        person_count: personCount,
        time_call: values?.BestTimeToCallYou,
        ride_type: values?.riderType,
        selection: values?.selection,
        trip_occasion: values?.trip_occasion,
        schedule: id,
      });

      resetForm({ initialValues });
      setSuccessModal(true);
    } catch (error) {
      // Если API возвращает ошибки
      if (error.response && error.response.data) {
        const apiErrors = error.response.data;

        // Отображаем ошибки, которые вернул API
        setErrors({
          email: apiErrors.email?.[0], // Отображаем ошибку для email
          phone_number: apiErrors.phone_number?.[0], // Отображаем ошибку для phone_number
        });
      }
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    city: Yup.number().required('City is required'),
    state: Yup.number().required('State is required'),
    height: Yup.number().required('Height is required'),
    address: Yup.string().required('Address is required'),
    country: Yup.number().required('Country is required'),
    last_name: Yup.string().required('Last name is required'),
    work_for: Yup.string().required('Occupation is required'),
    riderType: Yup.string().required('Rider type is required'),
    first_name: Yup.string().required('First name is required'),
    zip_code: Yup.string().required('Zip/Postal code is required'),
    selection: Yup.string().required('Bike selection is required'),
    t_shirt_size: Yup.number().required('Jersey size is required'),
    phone_number: Yup.string().required('Phone number is required'),
    date_of_birth: Yup.date().required('Date of birth is required'),
    is_newsletter: Yup.boolean().oneOf([true], 'Обязательное поле.'),
    trip_occasion: Yup.string().required('Trip occasion is required'),
    clothing_size: Yup.number().required('Clothing size is required'),
    travel_partner: Yup.string().required('Travel partner is required'),
    is_trip_protection: Yup.boolean().oneOf([true], 'Обязательное поле.'),
    BestTimeToCallYou: Yup.string().required('Best time to call is required'),
    // Message is not required
  });

  const getScheduleByIdToTour = async () => {
    const response = await getScheduleById(id);
    setTour(response);
  };

  const getCountriesToTour = async () => {
    const response = await getCountries();
    const CountryFieldOption = response?.data?.results?.map(Country => ({
      label: Country?.name,
      value: Country?.id,
    }));
    setCountries(CountryFieldOption);
  };

  const getStatesByCountry = async countryId => {
    const response = await getAllStates({ country__id: countryId });
    const StateFieldOption = response?.results?.map(state => ({
      label: state?.name,
      value: state?.id,
    }));
    setStates(StateFieldOption);
  };

  const getCitiesByState = async stateId => {
    const response = await getCities({ state__id: stateId });
    const CityFieldOption = response?.data?.results?.map(city => ({
      label: city?.name,
      value: city?.id,
    }));
    setCities(CityFieldOption);
  };

  const getClothingSizesOptions = async () => {
    const response = await getClothingSizes();
    const ClothingSizesFieldOption = response?.data?.results?.map(
      ClothingSize => ({
        label: ClothingSize?.name,
        value: ClothingSize?.id,
      }),
    );
    setClothingSizes(ClothingSizesFieldOption);
  };

  const getTShirtSizesOptions = async () => {
    const response = await getTShirtSizes();
    const TshirtSizesFieldOption = response?.results?.map(TshirtSize => ({
      label: TshirtSize?.name,
      value: TshirtSize?.id,
    }));

    setTShirtSizes(TshirtSizesFieldOption);
  };

  const handleCountryChange = selectedCountry => {
    getStatesByCountry(selectedCountry?.value);
    setCities([]);
  };

  const handleStateChange = selectedState => {
    getCitiesByState(selectedState?.value);
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      if (!User) return; // Если пользователь не авторизован, не продолжаем

      setLoading(true);

      await getCountriesToTour();

      if (User?.country) {
        await getStatesByCountry(User.country);
      }
      if (User?.state) {
        await getCitiesByState(User.state);
      }

      await Promise.all([
        getClothingSizesOptions(),
        getTShirtSizesOptions(),
        getScheduleByIdToTour(),
      ]);

      setInitialValues({
        first_name: User?.first_name || '',
        last_name: User?.last_name || '',
        phone_number: User?.phone_number || '',
        clothing_size: User?.clothing_size || '',
        t_shirt_size: User?.t_shirt_size || '',
        work_for: User?.work_for || '',
        email: User?.email || '',
        address: User?.address || '',
        country: User?.country || null,
        state: User?.state || null,
        city: User?.city || null,
        zip_code: User?.zip_code || '',
        height: User?.height || '',
        is_trip_protection: User?.is_trip_protection || false,
        is_newsletter: User?.is_newsletter || false,
        date_of_birth: User?.date_of_birth
          ? parse(User.date_of_birth, 'dd-MM-yyyy', new Date())
          : new Date(),
      });

      setLoading(false);
    };

    fetchInitialData();
  }, [User]); // Теперь User в зависимости, так что эффект сработает при изменении состояния пользователя

  return (
    <div className="TourForm">
      <Modal
        open={successModal}
        onClose={() => setSuccessModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="TourForm__successModal">
          <div className="TourForm__successModal-wrapper">
            <div className="TourForm__successModal-wrapper__tick">
              <TickCircle size="32" color="#ffffff" variant="Bold" />
            </div>
            <div className="TourForm__successModal-wrapper__info">
              <div className="TourForm__successModal-wrapper__info-text">
                <Headline3 variant={'dark900'}>Success!</Headline3>
                <MainText variant={'dark720'}>
                  We will get in touch shourtly to discuss the details of your
                  trip
                </MainText>
              </div>
              <div className="TourForm__successModal-wrapper__info-buttons">
                <ButtonM variant={'jungle500'} to={'/'}>
                  <ButtonMText variant={'white'}>Back to main</ButtonMText>
                </ButtonM>
                <ButtonM
                  onClick={() => setSuccessModal(false)}
                  type={'tertiary'}
                >
                  <ButtonMText variant={'jungle500'}>Close</ButtonMText>
                </ButtonM>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {!User ? (
        <div className="TourForm__notLogined container">
          <NotificationBing size="80" color="#007C6E" variant="Bold" />
          <MainText variant={'dark720'}>
            To book a tour: {tour?.title}, please log in
          </MainText>
          <ButtonM onClick={() => setLoginModalState(true)}>
            <ButtonMText variant={'white'}>Log in</ButtonMText>
          </ButtonM>
        </div>
      ) : (
        <div className="container">
          <div className="TourForm__wrapper">
            {loading ? (
              <Skeleton
                sx={{ fontSize: '32px', lineHeight: '44px', width: '80%' }}
              ></Skeleton>
            ) : (
              <Headline2 variant={'dark900'} textAlign={'center'}>
                {tour?.title}
              </Headline2>
            )}
            <DateToDate
              startDate={tour?.period_from}
              endDate={tour?.period_to}
              loading={loading}
            />
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={handleSubmit}
            >
              {({ isValid, dirty, errors, setFieldValue }) => (
                <Form className="TourForm__form" autoComplete="new-password">
                  <div className="TourForm__form-block">
                    <div className="TourForm__form-block__title">
                      <Headline3 variant={'dark900'}>
                        Contact information
                      </Headline3>
                      <Headline3 variant={'jungle500'}>01</Headline3>
                    </div>
                    <div className="TourForm__form-block__inputs">
                      <Field
                        name="first_name"
                        component={InputField}
                        loading={loading}
                        inputLabel="First name"
                        placeholder="Enter first name"
                        autoComplete="new-password"
                      />
                      <Field
                        name="last_name"
                        loading={loading}
                        component={InputField}
                        inputLabel="Last name"
                        placeholder="Enter last name"
                        autoComplete="new-password"
                      />
                      <Field
                        name="phone_number"
                        loading={loading}
                        component={InputField}
                        inputLabel="Phone number"
                        inputType="phone"
                        placeholder="Enter your phone number"
                        autoComplete="new-password"
                      />
                      <Field
                        name="BestTimeToCallYou"
                        loading={loading}
                        component={InputDrop}
                        inputLabel="Best time to call you"
                        autoComplete="new-password"
                        options={[
                          {
                            label: 'Between 08:00 AM & 11:00 AM',
                            value: 'BETWEEN_8AM_11AM',
                          },
                          {
                            label: 'Between 11:00 AM & 02:00 PM',
                            value: 'BETWEEN_11AM_2PM',
                          },
                          {
                            label: 'Between 02:00 PM & 06:00 PM',
                            value: 'BETWEEN_2PM_6PM',
                          },
                        ]}
                        placeholder="Best time to call you"
                      />
                      <Field
                        name="email"
                        loading={loading}
                        component={InputField}
                        autoComplete="new-password"
                        inputLabel="Email"
                        inputType="email"
                        placeholder="Enter your email"
                      />
                      <Field
                        name="address"
                        loading={loading}
                        component={InputField}
                        autoComplete="new-password"
                        inputLabel="Address"
                        placeholder="Enter your address"
                      />
                      <Field
                        name="country"
                        loading={loading}
                        component={InputDrop}
                        autoComplete="new-password"
                        inputLabel="Country"
                        options={countries}
                        onChangeHandler={selectedCountry =>
                          handleCountryChange(selectedCountry, setFieldValue)
                        }
                        placeholder="Select your country"
                      />
                      <Field
                        name="state"
                        loading={loading}
                        component={InputDrop}
                        autoComplete="new-password"
                        inputLabel="State"
                        options={states}
                        onChangeHandler={selectedState =>
                          handleStateChange(selectedState, setFieldValue)
                        }
                        placeholder="Select your state"
                        disabled={!states?.length > 0}
                      />
                      <Field
                        name="city"
                        loading={loading}
                        component={InputDrop}
                        autoComplete="new-password"
                        inputLabel="City"
                        options={cities}
                        placeholder="Select your city"
                        disabled={!cities?.length > 0}
                      />
                      <Field
                        name="zip_code"
                        loading={loading}
                        component={InputField}
                        autoComplete="new-password"
                        inputLabel="Zip/Postal code"
                        placeholder="Enter your Zip/Postal code"
                      />
                      <Field
                        name="date_of_birth"
                        loading={loading}
                        component={DateField}
                        autoComplete="new-password"
                        inputLabel="Date of birth"
                        placeholder="Select your Date of birth"
                        selectRange={false}
                        locale={'en-EN'}
                      />
                      <Field
                        name="travel_partner"
                        loading={loading}
                        component={InputField}
                        autoComplete="new-password"
                        inputLabel="Name of travel partner"
                        placeholder="Enter your travel partner"
                      />
                    </div>
                  </div>

                  <div className="TourForm__form-block">
                    <div className="TourForm__form-block__title">
                      <Headline3 variant={'dark900'}>
                        Your trip information
                      </Headline3>
                      <Headline3 variant={'jungle500'}>02</Headline3>
                    </div>
                    <div className="TourForm__form-block__inputs">
                      <Field
                        name="riderType"
                        loading={loading}
                        component={InputDrop}
                        autoComplete="new-password"
                        inputLabel="Rider type"
                        options={[
                          {
                            label: 'RIDE_SHORT',
                            value: 'RIDE_SHORT',
                          },
                          {
                            label: 'RIDE_TODAY',
                            value: 'RIDE_TODAY',
                          },
                          {
                            label: 'RIDE_AVID',
                            value: 'RIDE_AVID',
                          },
                        ]}
                        placeholder="Select rider type"
                      />
                      <Field
                        name="trip_occasion"
                        loading={loading}
                        component={InputField}
                        autoComplete="new-password"
                        inputLabel="Trip occasion"
                        placeholder="Select Trip occasion"
                      />
                      <Field
                        name="height"
                        loading={loading}
                        component={InputField}
                        autoComplete="new-password"
                        inputLabel="Your height"
                        placeholder="Your height"
                      />
                      <Field
                        name="selection"
                        loading={loading}
                        component={InputDrop}
                        autoComplete="new-password"
                        inputLabel="Bike selection"
                        options={[
                          {
                            label: 'ROAD',
                            value: 'ROAD',
                          },
                          {
                            label: 'ROAD_ELECTRO',
                            value: 'ROAD_ELECTRO',
                          },
                          {
                            label: 'ELECTRO_BIKE',
                            value: 'ELECTRO_BIKE',
                          },
                        ]}
                        placeholder="Select Bike selection"
                      />
                      <Field
                        name="clothing_size"
                        loading={loading}
                        component={InputDrop}
                        autoComplete="new-password"
                        inputLabel="Clothing size"
                        options={clothingSizes}
                        description={
                          'Excludes Ride Camp, Self-Guided, or Discover trips'
                        }
                        placeholder="Select Clothing size"
                      />
                      <Field
                        name="work_for"
                        loading={loading}
                        component={InputField}
                        autoComplete="new-password"
                        inputLabel="What is your Occupation"
                        placeholder="Select occupation"
                      />
                      <Field
                        name="t_shirt_size"
                        loading={loading}
                        component={InputDrop}
                        autoComplete="new-password"
                        inputLabel="Your jersey size"
                        options={tShirtSizes}
                        description={
                          'Trip Consultant who helped you (if applicable)'
                        }
                        placeholder="Select jersey size"
                      />
                      <Field
                        name="message"
                        loading={loading}
                        component={InputField}
                        autoComplete="new-password"
                        height={'104px'}
                        inputLabel="Message to Trip Consultant"
                        placeholder="Message to Trip Consultant"
                      />
                      <Field
                        name="is_trip_protection"
                        loading={loading}
                        component={SwitchField}
                        autoComplete="new-password"
                        label="Interested in Trip Protection*"
                      />
                      <Field
                        name="is_newsletter"
                        loading={loading}
                        component={SwitchField}
                        autoComplete="new-password"
                        label="Get the Trek Travel eNewsletter"
                      />
                    </div>
                  </div>

                  <div className="TourForm__form-warning">
                    <InfoCircle color="#3D67FF" variant="Bold" />
                    <DetailsText variant={'dark720'}>
                      Please wait for the confirmation message after clicking
                      submit below to ensure we received your information.
                    </DetailsText>
                  </div>

                  <ButtonL
                    variant={'primary'}
                    fullWidth={true}
                    action="submit"
                    disabled={!(isValid && dirty)}
                  >
                    <ButtonLText variant={'white'}>Submit</ButtonLText>
                  </ButtonL>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
};

export default TourForm;
