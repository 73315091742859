import { InputAdornment, TextField } from '@mui/material';
import React from 'react';

import './StyledTextField.scss';

const StyledTextField = ({
  variant,
  value,
  action,
  fullWidth,
  placeholder,
  startIcon,
  endIcon,
  className
}) => {
  return (
    <>
      <TextField
        variant={variant}
        onChange={action}
        value={value}
        placeholder={placeholder}
        fullWidth={fullWidth}
        className={`StyledTextField ${className}`}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{startIcon}</InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">{endIcon}</InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default StyledTextField;
