import React, { useEffect } from 'react';
import { Element } from 'react-scroll';
import './ScheduledPriceCard.scss';
import { ButtonS } from '../../../../components/Buttons/Buttons';
import {
  ButtonSText,
  DetailsText,
} from '../../../../components/Text/Body/Bodies';
import { Calendar } from 'iconsax-react';
import DateToDate from '../../../../components/DateToDate/DateToDate';
import { EuroSymbol } from '../../../../components/Ex/Ex';
import { Headline4 } from '../../../../components/Text/Headlines/Headlines';

const ScheduledPriceCard = ({ schedule, loading }) => {
  return (
    <Element className="ScheduledPriceCard" name="Schedule Card">
      <div className="ScheduledPriceCard__info">
        <div className="ScheduledPriceCard__info-dates">
          <div className="ScheduledPriceCard__info-dates__icon">
            <Calendar size="24" color="#ffffff" />
          </div>
          <DateToDate
            loading={loading}
            startDate={schedule?.period_from}
            endDate={schedule?.period_to}
          />
        </div>
        <div className="ScheduledPriceCard__info-price">
          <div className="ScheduledPriceCard__info-price__cost">
            <EuroSymbol color="dark540" size={'18px'} />
            <Headline4 variant={'dark900'}>{schedule?.price}</Headline4>
          </div>
          <div className="ScheduledPriceCard__info-price__access">
            {schedule?.type_access?.map(access => (
              <div className="ScheduledPriceCard__info-price__access-item">
                <div className="ScheduledPriceCard__info-price__access-item__dot dot"></div>
                <DetailsText variant={'dark720'}>{access?.title}</DetailsText>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="ScheduledPriceCard__book">
        <ButtonS
          type={'outlined'}
          variant={'jungle500'}
          iconRightShow={true}
          to={`/tour-form/${schedule?.id}`}
        >
          <ButtonSText variant={'jungle500'}>Book now</ButtonSText>
        </ButtonS>
      </div>
    </Element>
  );
};

export default ScheduledPriceCard;
