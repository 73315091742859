import { backend } from './../index';

const endpoint = '/states/';

export const getAllStates = async params => {
  try {
    const response = await backend.get(endpoint, { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching all states:', error);
    throw error;
  }
};

export const getStateById = async (id, params) => {
  try {
    const response = await backend.get(`${endpoint}${id}/`, { params });
    return response.data;
  } catch (error) {
    console.error(`Error fetching state with ID ${id}:`, error);
    throw error;
  }
};
