import { backend } from './../index';

export const getBlogs = async data => {
  try {
    const response = await backend.get('/blogs/', { params: data });
    return response;
  } catch (error) {
    console.error('Error fetching blogs:', error);
    throw error;
  }
};
