// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-button {
  padding: 8px;
  border-radius: 8px;
  background: #007c6e;
  color: #fff;
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Buttons/IconButton/IconButton.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;AACF","sourcesContent":[".icon-button {\n  padding: 8px;\n  border-radius: 8px;\n  background: #007c6e;\n  color: #fff;\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
