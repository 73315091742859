import React from 'react';
import './CountryCard.scss';
import { Link } from 'react-router-dom';
import { Headline3 } from '../../../../components/Text/Headlines/Headlines';

const CountryCard = ({ to, children, image }) => {
  return (
    <Link
      to={to}
      className="CountryCard"
    >
      <img src={image} alt='Switz'/>
      <Headline3 variant={'white'}>{children}</Headline3>
    </Link>
  );
};

export default CountryCard;
