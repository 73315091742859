import React from 'react';

import './HotelsCard.scss';
import { useEffect } from 'react';
import { Location, Star1 } from 'iconsax-react';
import {
  CaptionsText,
  DetailsText,
} from '../../../../components/Text/Body/Bodies';
import { Element } from 'react-scroll';
import {
  Headline4,
  Headline5,
} from '../../../../components/Text/Headlines/Headlines';

const HotelsCard = ({ hotel }) => {
  return (
    <Element className="HotelsCard" name="Hotel Card">
      <img
        src={hotel?.hotel_images[0]?.image}
        alt="No image"
        className="HotelsCard__image"
      />
      <div className="HotelsCard__bottom">
        <>
          <div className="HotelsCard__rating">
            <Star1 size="16" color="#f9ab02" variant="Bold" />
            <CaptionsText variant={'dark720'}>{hotel?.rating}</CaptionsText>
          </div>

          <Headline5 variant={'dark900'}>{hotel?.name}</Headline5>

          <div className="HotelsCard__geo">
            <Location size="16" color="#747b8a" />
            <CaptionsText variant={'dark720'}>{hotel?.street}</CaptionsText>
          </div>
        </>
        <DetailsText variant={'dark720'}>{hotel?.description}</DetailsText>
      </div>
    </Element>
  );
};

export default HotelsCard;
