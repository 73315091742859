import './OurBlog.scss';
import React from 'react';
import OurBlogCard from './components/OurBlogCard/OurBlogCard';
import { Headline2 } from '../../../../components/Text/Headlines/Headlines';
import PageContainer from '../../../../components/PageContainer/PageContainer';

const OurBlog = ({ blogs }) => {
  return (
    blogs?.length > 0 && (
      <div className="OurBlog">
        <PageContainer>
          <Headline2>Our Blog</Headline2>

          <div className="OurBlog__content">
            {blogs?.slice(0, 4)?.map(blog => (
              <OurBlogCard item={blog} />
            ))}
          </div>
        </PageContainer>
      </div>
    )
  );
};

export default OurBlog;
