import './Benefits.scss';
import React from 'react';
import BenefitsCard from './components/BenefitsCard/BenefitsCard';
import { Headline2 } from '../../../../components/Text/Headlines/Headlines';
import PageContainer from '../../../../components/PageContainer/PageContainer';

const Benefits = ({ benefits }) => {
  return (
    benefits?.length > 0 && (
      <div className="Benefits">
        <PageContainer>
          <Headline2>Benefits</Headline2>

          <div className="Benefits__cards">
            {benefits?.map(item => (
              <BenefitsCard
                icon={item.icon}
                title={item.title}
                description={item.description}
              />
            ))}
          </div>
        </PageContainer>
      </div>
    )
  );
};

export default Benefits;
