import React from 'react';
import './PopularDecisions.scss';
import { Headline2 } from '../../../../components/Text/Headlines/Headlines';
import PageContainer from '../../../../components/PageContainer/PageContainer';
import PopularDecisionsCard from './components/PopularDecisionsCard/PopularDecisionsCard';
import OutlinedButton from '../../../../components/Buttons/OutlinedButton/OutlinedButton';
import PopularDecisionCardImg from '../../../../assets/images/Main/PopularDecisions/CaminoDeSantiago.png';

const PopularDecisions = ({ popularDecisions }) => {
  return (
    popularDecisions?.length > 0 && (
      <div className="PopularDecisions">
        <PageContainer>
          <Headline2>Popular Decisions</Headline2>

          <div className="PopularDecisions__content">
            <div className="PopularDecisions__cards">
              {popularDecisions?.map(decision => {
                return (
                  <PopularDecisionsCard
                    image={decision?.image || PopularDecisionCardImg}
                    sportType={decision?.tour_type__title}
                    location={`${decision?.country__name}, ${decision?.state__name}, ${decision?.city__name}`}
                    title={decision?.title}
                    price={'Нету на Бэке'}
                    currency={'Нету на Бэке'}
                    time={decision?.duration_day}
                    date={decision?.schedule_data}
                  />
                );
              })}
            </div>

            <OutlinedButton text={'View all'} />
          </div>
        </PageContainer>
      </div>
    )
  );
};

export default PopularDecisions;
