import React from 'react';
import { Headline2, Headline4 } from '../Text/Headlines/Headlines';
import { Setting4 } from 'iconsax-react';
import ToursCard from '../../pages/DestinationDetail/components/ToursCard/ToursCard';
import './Tours.scss';
import { Field, Form, Formik } from 'formik';
import InputDrop from '../InputDrop/InputDrop';
import DateField from '../../pages/TourForm/components/DateField/DateField';

const Tours = ({ tours, country, loading }) => {
  return (
    <div className="Tours container">
      <div className="Tours__header">
        <Headline2 className="Tours__header-title" variant={'dark900'}>
          Tours
        </Headline2>
        <div className="Tours__header-filters">
          <div className="Tours__header-filters__text">
            <Setting4 color="#747b8a" />
            <Headline4 variant={'dark900'}>Filter by</Headline4>
          </div>
          <Formik className="Tours__header-filters__form">
            <Form className="Tours__header-filters__form-selectors">
              <Field
                name="activity"
                loading={loading}
                component={InputDrop}
                options={[{ label: 'Activity', value: 'askdmasl' }]}
                inputLabel="Activity"
                placeholder="Activity"
                backgroundColor={'#fff'}
              />
              <Field
                name="activity"
                loading={loading}
                component={DateField}
                inputLabel="Activity"
                placeholder="Activity"
                backgroundColor={'#fff'}
              />
              <Field
                name="activity"
                loading={loading}
                component={InputDrop}
                options={[{ label: 'Travelers', value: 'askdmasl' }]}
                inputLabel="Travelers"
                backgroundColor={'#fff'}
                placeholder="Travelers"
              />
            </Form>
          </Formik>
        </div>
      </div>

      <div className="Tours__cards">
        {loading ? (
          // Empty Cards
          <>
            <ToursCard loading={loading} />
            <ToursCard loading={loading} />
            <ToursCard loading={loading} />
            <ToursCard loading={loading} />
            <ToursCard loading={loading} />
            <ToursCard loading={loading} />
          </>
        ) : (
          tours.map((tour, index) => (
            <ToursCard
              idx={index}
              country={country}
              tour={tour}
              loading={loading}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default Tours;
