import React from 'react';

import './Footer.scss';
import { Link } from 'react-router-dom';

import HeaderLogo from '../../assets/images/headerLogo.svg';
import SMSIcon from '../../assets/icons/sms.svg';
import CallIcon from '../../assets/icons/call.svg';
import StyledTextField from '../../components/StyledTextField/StyledTextField';

import FacebookIcon from '../../assets/icons/facebook.svg';
import InstagramIcon from '../../assets/icons/instagram.svg';
import YoutubeIcon from '../../assets/icons/youtube.svg';
import PageContainer from '../../components/PageContainer/PageContainer';

const Footer = () => {
  return (
    <footer className="footer">
      <PageContainer>
        <div className="footer__content">
          <div className="footer__top">
            <Link to={'/'}>
              <img
                src={HeaderLogo}
                alt="Voyage logo"
                className="footer__top__logo"
              />
            </Link>
            <div className="footer__top__search">
              <StyledTextField placeholder={'Search'} />
            </div>
          </div>

          <div className="footer__links">
            <div className="footer__links__block">
              <h3 className="footer__links__block__title">Destinations</h3>
              <Link className="footer__links__block__link">Switzerland</Link>
              <Link className="footer__links__block__link">France</Link>
              <Link className="footer__links__block__link">Italy</Link>
              <Link className="footer__links__block__link">Spain</Link>
            </div>
            <div className="footer__links__block">
              <h3 className="footer__links__block__title">What we provide</h3>
              <Link className="footer__links__block__link">Cycling</Link>
              <Link className="footer__links__block__link">Youga</Link>
              <Link className="footer__links__block__link">Hiking</Link>
            </div>{' '}
            <div className="footer__links__block">
              <h3 className="footer__links__block__title">About us</h3>
              <Link className="footer__links__block__link">Our blog</Link>
              <Link className="footer__links__block__link">Reviews</Link>
              <Link className="footer__links__block__link">Gallery</Link>
            </div>{' '}
            <div className="footer__links__block">
              <h3 className="footer__links__block__title">Contacts</h3>
              <a
                href="callto: +7 *** *** ****"
                className="footer__links__block__link"
              >
                <img src={CallIcon} alt="" />
                +7 *** *** ****
              </a>
              <a
                href="mailto: Nt.vouage@gmail.com"
                className="footer__links__block__link"
              >
                <img src={SMSIcon} alt="" />
                Nt.vouage@gmail.com
              </a>

              <div className="footer__links__block__social">
                <img
                  src={FacebookIcon}
                  alt=""
                  className="footer__links__block__social__img"
                />
                <img
                  src={InstagramIcon}
                  alt=""
                  className="footer__links__block__social__img"
                />
                <img
                  src={YoutubeIcon}
                  alt=""
                  className="footer__links__block__social__img"
                />
              </div>
            </div>
          </div>

          <div className="footer__bottom">
            <p className="footer__bottom__copyright">
              Vouage 2024. © All rights reserved
            </p>
          </div>
        </div>
      </PageContainer>
    </footer>
  );
};

export default Footer;
