import React from 'react';
import { TextField, Autocomplete, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArrowDown2 } from 'iconsax-react';
import './InputDrop.scss';
import { Headline5 } from '../Text/Headlines/Headlines';
import { CaptionsText } from '../Text/Body/Bodies';

// Styled component with dynamic background color
const StyledTextField = styled(TextField)(({ backgroundColor }) => ({
  '& .MuiFilledInput-root': {
    backgroundColor: backgroundColor || '#F5F6F7', // Default or provided color
    borderRadius: 12,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#F5F6F7',
    '&:hover': {
      backgroundColor: '#EDEFF2',
    },
    '& input::placeholder': {
      color: '#4E5666',
    },
    '&.Mui-focused': {
      borderColor: '#007C6E',
      borderWidth: 1,
      borderStyle: 'solid',
      backgroundColor: backgroundColor || '#F5F6F7',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#4E5666',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#4E5666',
  },
  '& .MuiFilledInput-underline:before': {
    borderBottom: 'none',
    display: 'none',
  },
  '& .MuiFilledInput-underline:after': {
    borderBottom: 'none',
    display: 'none',
  },
}));

const InputDrop = ({
  field,
  form,
  inputLabel,
  label,
  placeholder,
  options,
  description,
  className,
  backgroundColor,
  onChangeHandler,
  loading,
  ...props
}) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  return (
    <div className="InputDrop">
      {label && (
        <Headline5 variant={'dark900'}>
          {loading ? <Skeleton width={'50%'}></Skeleton> : label}
        </Headline5>
      )}
      {loading ? (
        <Skeleton
          variant="rounded"
          sx={{ maxWidth: '356px', width: '100%', height: '56px' }}
        ></Skeleton>
      ) : (
        <Autocomplete
          value={options.find(option => option.value === value) || null}
          options={options}
          className={className}
          getOptionLabel={option => option.label}
          popupIcon={<ArrowDown2 />}
          isOptionEqualToValue={(option, value) => option.value === value}
          onChange={(event, newValue) => {
            setFieldValue(name, newValue ? newValue.value : ''); // Handle value change
            onChangeHandler && onChangeHandler(newValue);
          }}
          renderInput={params => (
            <StyledTextField
              {...params}
              label={inputLabel}
              hiddenLabel={!inputLabel} // Hide label if not provided
              fullWidth
              variant="filled"
              placeholder={placeholder || 'Select an option'}
              backgroundColor={backgroundColor} // Pass background color to styles
            />
          )}
          {...props}
        />
      )}
      {description && (
        <CaptionsText className="InputDrop__description">
          {loading ? <Skeleton width={'80%'}></Skeleton> : description}
        </CaptionsText>
      )}
    </div>
  );
};

export default InputDrop;
