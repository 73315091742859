export const colors = {
  dark900: '#1D2026',
  dark720: '#4E5666',
  dark540: '#747B8A',
  dark360: '#878D99',
  dark180: '#A4A9B2',
  white: '#FFFFFF',
  black: '#000000',
  light100: '#F5F6F7',
  light200: '#EDEFF2',
  light300: '#E4E6EB',
  light400: '#D7DAE0',
  jungle900: '#001916',
  jungle800: '#00322C',
  jungle700: '#004A42',
  jungle600: '#006358',
  jungle500: '#007C6E',
  jungle400: '#47A197',
  jungle300: '#85C0B9',
  jungle200: '#C2DFDC',
  jungle100: '#EBF5F3',
  peaches500: '#FFA17A',
  peaches400: '#FFBB9F',
  peaches300: '#FFD2BF',
  peaches200: '#FFE8DF',
  peaches100: '#FFF7F4',
  info500: '#3D67FF',
  info400: '#7391FF',
  info300: '#A2B6FF',
  info200: '#D0DAFF',
  info100: '#EFF3FF',
  warning500: '#F9AB02',
  warning400: '#FBC249',
  warning300: '#FCD786',
  warning200: '#FEEBC2',
  warning100: '#FFF8EB',
  success500: '#30AA25',
  success400: '#6AC262',
  success300: '#9CD696',
  success200: '#CDEBCB',
  success100: '#EEF8EE',
  danger500: '#E0332A',
  danger400: '#E96C66',
  danger300: '#F09D99',
  danger200: '#F8CECC',
  danger100: '#FDEFEE',
};
