import { backend } from './../index';

const endpoint = '/auth/signin/';

export const signIn = async data => {
  try {
    const response = await backend.post(endpoint, data);

    localStorage.setItem('access_token', response?.data?.access);
    localStorage.setItem('refresh_token', response?.data?.refresh);
    localStorage.setItem('user', JSON.stringify(response?.data?.user));

    return response.data;
  } catch (error) {
    console.error('Error signing in:', error);
    throw error;
  }
};
