import React from 'react';

const SunriseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.88164 12.5526C3.19977 13.1346 2.35676 13.8542 1.05263 13.8542C0.747422 13.8542 0.5 13.6068 0.5 13.3016C0.5 12.9964 0.747422 12.7489 1.05263 12.7489C1.91998 12.7489 2.48085 12.2949 3.19417 11.6863C3.20945 11.6732 3.22481 11.6601 3.24025 11.6469C3.91514 11.0705 4.74806 10.3592 6.02632 10.3592C7.30761 10.3592 8.14985 11.0737 8.83114 11.6517C8.84433 11.6629 8.85746 11.6741 8.87053 11.6852C9.59168 12.2966 10.1512 12.7489 11 12.7489C11.8488 12.7489 12.4083 12.2966 13.1295 11.6852C13.1425 11.6741 13.1557 11.6629 13.1689 11.6517C13.8502 11.0737 14.6924 10.3592 15.9737 10.3592C17.2519 10.3592 18.0849 11.0705 18.7597 11.6469C18.7752 11.6601 18.7906 11.6732 18.8058 11.6863C19.5192 12.2949 20.08 12.7489 20.9474 12.7489C21.2526 12.7489 21.5 12.9964 21.5 13.3016C21.5 13.6068 21.2526 13.8542 20.9474 13.8542C19.6432 13.8542 18.8002 13.1346 18.1184 12.5526L18.0884 12.5271C17.3711 11.915 16.8211 11.4644 15.9737 11.4644C15.1249 11.4644 14.5654 11.9168 13.8442 12.5282C13.8311 12.5393 13.818 12.5504 13.8048 12.5616C13.1235 13.1396 12.2813 13.8542 11 13.8542C9.7187 13.8542 8.87647 13.1396 8.19518 12.5616C8.18199 12.5504 8.16886 12.5393 8.15578 12.5282C7.43464 11.9168 6.87513 11.4644 6.02632 11.4644C5.17891 11.4644 4.62892 11.915 3.91155 12.5271C3.90161 12.5356 3.89164 12.5441 3.88164 12.5526Z"
        fill="#747B8A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.88164 15.739C3.19977 16.321 2.35676 17.0405 1.05263 17.0405C0.747422 17.0405 0.5 16.7931 0.5 16.4879C0.5 16.1827 0.747422 15.9353 1.05263 15.9353C1.91998 15.9353 2.48085 15.4812 3.19417 14.8726C3.20945 14.8596 3.22481 14.8465 3.24025 14.8333C3.91514 14.2569 4.74806 13.5455 6.02632 13.5455C7.30761 13.5455 8.14985 14.2601 8.83114 14.8381C8.84433 14.8493 8.85746 14.8604 8.87053 14.8715C9.59168 15.4829 10.1512 15.9353 11 15.9353C11.8488 15.9353 12.4083 15.4829 13.1295 14.8715C13.1425 14.8604 13.1557 14.8493 13.1689 14.8381C13.8502 14.2601 14.6924 13.5455 15.9737 13.5455C17.2519 13.5455 18.0849 14.2569 18.7597 14.8333C18.7752 14.8465 18.7906 14.8596 18.8058 14.8726C19.5192 15.4812 20.08 15.9353 20.9474 15.9353C21.2526 15.9353 21.5 16.1827 21.5 16.4879C21.5 16.7931 21.2526 17.0405 20.9474 17.0405C19.6432 17.0405 18.8002 16.321 18.1184 15.739L18.0884 15.7134C17.3711 15.1014 16.8211 14.6508 15.9737 14.6508C15.1249 14.6508 14.5654 15.1032 13.8442 15.7146C13.8311 15.7256 13.818 15.7368 13.8048 15.748C13.1235 16.326 12.2813 17.0405 11 17.0405C9.7187 17.0405 8.87647 16.326 8.19518 15.748C8.18199 15.7368 8.16886 15.7256 8.15578 15.7146C7.43464 15.1032 6.87513 14.6508 6.02632 14.6508C5.17891 14.6508 4.62892 15.1014 3.91155 15.7134C3.90161 15.7219 3.89164 15.7304 3.88164 15.739Z"
        fill="#747B8A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.91137 7.29372C7.28858 5.37448 8.98019 3.92674 11.01 3.92674C13.0379 3.92674 14.7282 5.37182 15.1075 7.28845C15.3733 7.21519 15.6606 7.17283 15.9737 7.17283C17.2519 7.17283 18.0849 7.88419 18.7597 8.46059C18.7752 8.47378 18.7906 8.4869 18.8058 8.49994C19.3754 8.98586 20.2 9.37325 20.9508 9.50969C21.2511 9.56426 21.5 9.81001 21.5 10.1152V10.1152C21.5 10.4204 21.2515 10.6704 20.9493 10.6279C19.8192 10.4691 18.7048 9.86684 18.1184 9.36628L18.0884 9.34075C17.3711 8.72868 16.8211 8.27809 15.9737 8.27809C15.5613 8.27809 15.2186 8.38622 14.898 8.56031C14.6391 8.7009 14.3935 8.88497 14.1322 9.10006C14.0468 9.17039 13.9551 9.24808 13.8599 9.32869C13.6828 9.47872 13.4938 9.63887 13.3109 9.78057C12.717 10.2407 11.9949 10.6679 11 10.6679C10.0105 10.6679 9.29093 10.2454 8.69878 9.78804C8.51166 9.64351 8.32013 9.48123 8.14085 9.32933C8.0502 9.25251 7.96267 9.17835 7.88043 9.11048C7.62023 8.89575 7.37599 8.71133 7.11868 8.56945C6.79295 8.38983 6.44554 8.27809 6.02632 8.27809C5.17891 8.27809 4.62892 8.72868 3.91155 9.34075C3.90161 9.34923 3.89164 9.35774 3.88164 9.36628C3.19977 9.94829 2.35676 10.6679 1.05263 10.6679C0.747422 10.6679 0.5 10.4204 0.5 10.1152C0.5 9.81001 0.747422 9.56259 1.05263 9.56259C1.91998 9.56259 2.48085 9.10854 3.19417 8.49994C3.20945 8.4869 3.22481 8.47378 3.24025 8.46059C3.91514 7.88419 4.74806 7.17283 6.02632 7.17283C6.34691 7.17283 6.64033 7.21724 6.91137 7.29372ZM7.9545 7.78466C8.18158 7.93433 8.39012 8.09808 8.58393 8.25802C8.69082 8.34623 8.78955 8.43 8.88487 8.51087C9.05004 8.651 9.20498 8.78246 9.3744 8.91331C9.88013 9.30393 10.3625 9.56259 11 9.56259C11.6409 9.56259 12.1251 9.30116 12.6339 8.90688C12.8012 8.77724 12.953 8.64842 13.1146 8.51127C13.214 8.42694 13.3171 8.33946 13.4298 8.24671C13.6249 8.08609 13.8351 7.92197 14.0641 7.77244C13.8989 6.23181 12.5945 5.03201 11.01 5.03201C9.42121 5.03201 8.11414 6.23815 7.9545 7.78466Z"
        fill="#747B8A"
      />
      <path
        d="M8.58393 8.25802C8.39012 8.09808 8.18158 7.93433 7.9545 7.78466C8.11414 6.23815 9.42121 5.03201 11.01 5.03201C12.5945 5.03201 13.8989 6.23181 14.0641 7.77244C13.8351 7.92197 13.6249 8.08609 13.4298 8.24671C13.3171 8.33946 13.214 8.42694 13.1146 8.51127C12.953 8.64842 12.8012 8.77724 12.6339 8.90688C12.1251 9.30116 11.6409 9.56259 11 9.56259C10.3625 9.56259 9.88013 9.30393 9.3744 8.91331C9.20498 8.78246 9.05004 8.651 8.88487 8.51087C8.78955 8.43 8.69082 8.34623 8.58393 8.25802Z"
        fill="#747B8A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.9511 0.959457C11.2563 0.959457 11.5038 1.20688 11.5038 1.51209V3.26458C11.5038 3.56979 11.2563 3.81721 10.9511 3.81721C10.6459 3.81721 10.3985 3.56979 10.3985 3.26458V1.51209C10.3985 1.20688 10.6459 0.959457 10.9511 0.959457Z"
        fill="#747B8A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.69174 2.36184C6.93608 2.17895 7.28243 2.22877 7.46531 2.47312L8.51545 3.87613C8.69834 4.12048 8.64852 4.46682 8.40417 4.64971C8.15983 4.8326 7.81349 4.78278 7.6306 4.53843L6.58046 3.13542C6.39757 2.89107 6.44739 2.54473 6.69174 2.36184Z"
        fill="#747B8A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.2001 2.36184C14.9558 2.17895 14.6094 2.22877 14.4265 2.47312L13.3764 3.87613C13.1935 4.12048 13.2433 4.46682 13.4877 4.64971C13.732 4.8326 14.0784 4.78278 14.2613 4.53843L15.3114 3.13542C15.4943 2.89107 15.4445 2.54473 15.2001 2.36184Z"
        fill="#747B8A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.04872 6.46689C7.10093 6.16618 6.89948 5.88008 6.59877 5.82787L4.87212 5.52806C4.57141 5.47585 4.2853 5.6773 4.23309 5.97801C4.18088 6.27872 4.38232 6.56482 4.68304 6.61703L6.40969 6.91684C6.7104 6.96905 6.9965 6.7676 7.04872 6.46689Z"
        fill="#747B8A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.9472 6.46689C14.895 6.16618 15.0964 5.88008 15.3971 5.82787L17.1238 5.52806C17.4245 5.47585 17.7106 5.6773 17.7628 5.97801C17.815 6.27872 17.6136 6.56482 17.3129 6.61703L15.5862 6.91684C15.2855 6.96905 14.9994 6.7676 14.9472 6.46689Z"
        fill="#747B8A"
      />
    </svg>
  );
};

export default SunriseIcon;
