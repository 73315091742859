import React from 'react';
import './Bodies.scss';
import { colors } from '../Colors/Colors';

export const ImportantText = ({ children, className, variant }) => {
  return (
    <p
      className={`ImportantText RegularText ${className}`}
      style={{ color: colors[variant] || colors.black }}
    >
      {children}
    </p>
  );
};

export const MainText = ({ children, variant }) => {
  return (
    <p
      className="MainText RegularText"
      style={{ color: colors[variant] || colors.black }}
    >
      {children}
    </p>
  );
};

export const DetailsText = ({ children, variant, textAlign }) => {
  return (
    <p
      className="DetailsText RegularText"
      style={{
        color: colors[variant] || colors.black,
        textAlign: textAlign || 'left',
      }}
    >
      {children}
    </p>
  );
};

export const DetailsTextSetInnerHtml = ({ children, variant, textAlign }) => {
  return (
    <p
      className="DetailsText RegularText"
      style={{
        color: colors[variant] || colors.black,
        textAlign: textAlign || 'left',
      }}
      dangerouslySetInnerHTML={{ __html: children }}
    ></p>
  );
};

export const CaptionsText = ({ children, variant }) => {
  return (
    <p
      className="CaptionsText RegularText"
      style={{ color: colors[variant] || colors.black }}
    >
      {children}
    </p>
  );
};

export const ButtonLText = ({ children, variant }) => {
  return (
    <p
      className="ButtonLText MediumText"
      style={{ color: colors[variant] || colors.black }}
    >
      {children}
    </p>
  );
};

export const ButtonMText = ({ children, variant }) => {
  return (
    <p
      className="ButtonMText MediumText"
      style={{ color: colors[variant] || colors.black }}
    >
      {children}
    </p>
  );
};

export const ButtonSText = ({ children, variant }) => {
  return (
    <p
      className="ButtonSText MediumText"
      style={{ color: colors[variant] || colors.black }}
    >
      {children}
    </p>
  );
};
