// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BenefitsCard {
  padding: 16px 48px 16px 32px;
  background: #fff;
  display: flex;
  flex-direction: column;
  width: 50%;
  row-gap: 24px;
}
.BenefitsCard:nth-child(2) {
  border-left: 1px solid #e4e6eb;
  border-right: 1px solid #e4e6eb;
}
.BenefitsCard:first-child {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}
.BenefitsCard:last-child {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}
.BenefitsCard__icon {
  width: 56px;
}
.BenefitsCard__title {
  font-family: Mulish, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  color: #1d2026;
}
.BenefitsCard__description {
  font-family: Mulish, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #4e5666;
}`, "",{"version":3,"sources":["webpack://./src/pages/Main/components/Benefits/components/BenefitsCard/BenefitsCard.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,UAAA;EAEA,aAAA;AAAF;AAEE;EACE,8BAAA;EACA,+BAAA;AAAJ;AAGE;EACE,4BAAA;EACA,+BAAA;AADJ;AAIE;EACE,6BAAA;EACA,gCAAA;AAFJ;AAKE;EACE,WAAA;AAHJ;AAME;EACE,+BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AAJJ;AAOE;EACE,+BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AALJ","sourcesContent":[".BenefitsCard {\n  padding: 16px 48px 16px 32px;\n  background: #fff;\n  display: flex;\n  flex-direction: column;\n  width: 50%;\n\n  row-gap: 24px;\n\n  &:nth-child(2) {\n    border-left: 1px solid #e4e6eb;\n    border-right: 1px solid #e4e6eb;\n  }\n\n  &:first-child {\n    border-top-left-radius: 16px;\n    border-bottom-left-radius: 16px;\n  }\n\n  &:last-child {\n    border-top-right-radius: 16px;\n    border-bottom-right-radius: 16px;\n  }\n\n  &__icon {\n    width: 56px;\n  }\n\n  &__title {\n    font-family: Mulish, sans-serif;\n    font-size: 20px;\n    font-weight: 700;\n    line-height: 28px;\n    text-align: left;\n    color: #1d2026;\n  }\n\n  &__description {\n    font-family: Mulish, sans-serif;\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n    text-align: left;\n    color: #4e5666;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
