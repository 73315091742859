import './styles/index.scss';
import { useEffect } from 'react';
import routes from './routing/routes';
import { useRecoilState } from 'recoil';
import Login from './components/Login/Login';
import 'react-toastify/dist/ReactToastify.css';
import { userState } from './store/users/users.store';
import { ToastContainer } from 'react-toastify';
import PageLayout from './shared/PageLayout/PageLayout';
import { useLocation, useRoutes } from 'react-router-dom';
import Registration from './components/Registration/Registration';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Прокручивает страницу к началу
  }, [pathname]); // Этот эффект будет выполняться при каждом изменении пути

  return null;
};

function App() {
  const content = useRoutes(routes);
  const [User, setUser] = useRecoilState(userState);

  useEffect(() => {
    !User?.id && setUser(JSON.parse(localStorage.getItem('user')));
  }, [User, setUser]);

  return (
    <PageLayout>
      <ScrollToTop />
      <Login />
      <Registration />
      <ForgotPassword />
      <ToastContainer />
      {content}
    </PageLayout>
  );
}

export default App;
