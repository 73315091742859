import React from 'react';

import './TopComponent.scss';
import FilledButton from '../../../../components/Buttons/FilledButton/FilledButton';

import { useNavigate } from 'react-router-dom';

const TopComponent = () => {
  const navigate = useNavigate();
  return (
    <div className="TopComponent">
      <h1 className="TopComponent__title">
        Along the Rhône River from Geneva to Lyon
      </h1>

      <p className="TopComponent__description">
        Discover nature's beauty on our cycling trips! Expert-guided, <br />{' '}
        diverse landscapes, suitable for all.
      </p>

      <FilledButton
        action={() => navigate('/destinations')}
        text={'Book now'}
      />
    </div>
  );
};

export default TopComponent;
