import { backend } from './../index';

const endpoint = '/forgot_password';

export const сheckCodeOnForgotPassword = async data => {
  try {
    const response = await backend.post(`${endpoint}/check_code/`, data);
    return response.data;
  } catch (error) {
    console.error('Error checking password code:', error);
    throw error;
  }
};

export const createNewPasswordOnForgotPassword = async data => {
  try {
    const response = await backend.post(`${endpoint}/new_password/`, data, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('LCK'))}`,
      },
    });
    return response;
  } catch (error) {
    console.error('Error creating new password:', error);
    throw error;
  }
};

export const passwordSendCodeOnForgotPassword = async data => {
  try {
    const response = await backend.post(`${endpoint}/send_code/`, data);
    return response;
  } catch (error) {
    console.error('Error sending password code:', error);
    throw error;
  }
};
