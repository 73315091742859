import React, { useState } from 'react';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import './FormInput.scss';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiFilledInput-root': {
      backgroundColor: '#F5F6F7',
      borderRadius: 8,
      '&:hover': {
        backgroundColor: '#E5E6E7',
      },
      '&.Mui-focused': {
        borderColor: '#007C6E',
        borderWidth: 1,
        borderStyle: 'solid',
        backgroundColor: '#F5F6F7',
      },
    },
    '& .MuiInputLabel-root': {
      color: '#4E5666',
    },
    '& .MuiFilledInput-underline:before': {
      borderBottom: 'none',
      display: 'none',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: 'none',
      display: 'none',
    },
  },
}));

const FormInput = ({ field, form, label, inputLabel, type }) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = event => event.preventDefault();

  return (
    <div className="FormInput">
      <p className="FormInput__label">{label}</p>
      <TextField
        {...field}
        label={inputLabel}
        fullWidth
        variant="filled"
        type={showPassword ? 'text' : type}
        className={classes.root}
        error={Boolean(form.errors[field.name] && form.touched[field.name])}
        helperText={
          form.errors[field.name] && form.touched[field.name]
            ? form.errors[field.name]
            : ''
        }
        InputProps={
          type === 'password'
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : null
        }
      />
    </div>
  );
};

export default FormInput;
