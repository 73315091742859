// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.WhatSayAboutUsCard {
  background: #ffffff;
  padding: 16px 24px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 384px;
  width: 100%;
}
.WhatSayAboutUsCard .DetailsText {
  display: flex;
  align-items: center;
  gap: 8px;
}
.WhatSayAboutUsCard__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 336px;
  width: 100%;
}
.WhatSayAboutUsCard__content .MainText {
  display: -webkit-box; /* Используем flexbox контейнер с отображением в виде коробки */
  -webkit-box-orient: vertical; /* Указываем вертикальную ориентацию */
  overflow: hidden; /* Скрываем текст, выходящий за пределы контейнера */
  text-overflow: ellipsis; /* Добавляем троеточие в конце */
  -webkit-line-clamp: 3; /* Ограничиваем количество строк до 3 */
}
.WhatSayAboutUsCard__content-rating {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.WhatSayAboutUsCard__content-rating .MainText {
  display: flex;
  align-items: center;
  gap: 8px;
}`, "",{"version":3,"sources":["webpack://./src/pages/AboutUs/components/WhatSayAboutUsCard/WhatSayAboutUsCard.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,mBAAA;EACA,kBAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;EACA,WAAA;AAEF;AAAE;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AAEJ;AACE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;EACA,WAAA;AACJ;AACI;EACE,oBAAA,EAAA,+DAAA;EACA,4BAAA,EAAA,sCAAA;EACA,gBAAA,EAAA,oDAAA;EACA,uBAAA,EAAA,gCAAA;EACA,qBAAA,EAAA,uCAAA;AACN;AAEI;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AAAN;AAEM;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AAAR","sourcesContent":[".WhatSayAboutUsCard {\n  background: #ffffff;\n  padding: 16px 24px;\n  border-radius: 16px;\n  display: flex;\n  flex-direction: column;\n  gap: 32px;\n  max-width: 384px;\n  width: 100%;\n\n  .DetailsText {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n  }\n\n  &__content {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n    max-width: 336px;\n    width: 100%;\n\n    .MainText {\n      display: -webkit-box; /* Используем flexbox контейнер с отображением в виде коробки */\n      -webkit-box-orient: vertical; /* Указываем вертикальную ориентацию */\n      overflow: hidden; /* Скрываем текст, выходящий за пределы контейнера */\n      text-overflow: ellipsis; /* Добавляем троеточие в конце */\n      -webkit-line-clamp: 3; /* Ограничиваем количество строк до 3 */\n    }\n\n    &-rating {\n      display: flex;\n      flex-direction: column;\n      gap: 8px;\n\n      .MainText {\n        display: flex;\n        align-items: center;\n        gap: 8px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
