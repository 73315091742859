import { backend } from './../index';

export const getBenefits = async data => {
  try {
    const response = await backend.get('/benefits/', { params: data });
    return response;
  } catch (error) {
    console.error('Error fetching benefits:', error);
    throw error;
  }
};
