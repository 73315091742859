// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Benefits__cards {
  margin-top: 24px;
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/pages/Main/components/Benefits/Benefits.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,aAAA;AAAJ","sourcesContent":[".Benefits {\n  &__cards {\n    margin-top: 24px;\n    display: flex;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
