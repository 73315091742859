import { backend } from './../index';

const endpoint = '/sign_up/';

export const checkCode = async data => {
  try {
    const response = await backend.post(`${endpoint}check_code/`, data);
    return response.data;
  } catch (error) {
    console.error('Error checking code:', error);
    throw error;
  }
};

export const createPassword = async data => {
  try {
    const response = await backend.post(`${endpoint}create_password/`, data, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('LCK'))}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating password:', error);
    throw error;
  }
};

export const sendCode = async data => {
  try {
    const response = await backend.post(`${endpoint}send_code/`, data);
    return response.data;
  } catch (error) {
    console.error('Error sending code:', error);
    throw error;
  }
};
