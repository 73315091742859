import { backend } from './../index';

const endpoint = '/t-shirt-sizes/';

export const getTShirtSizes = async params => {
  try {
    const response = await backend.get(endpoint, { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching T-shirt sizes:', error);
    throw error;
  }
};
