import React, { useState } from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import DatePicker from 'react-date-picker'; // Импортируйте компонент для выбора одной даты
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import './DateRangePickerField.scss';

const DateRangePickerField = ({ selectRange = true, ...props }) => {
  const [value, setValue] = useState(
    selectRange ? [new Date(), new Date()] : new Date(),
  );

  const handleChange = newDate => {
    setValue(newDate);
  };

  return (
    <div>
      {selectRange ? (
        <DateRangePicker onChange={handleChange} value={value} {...props} />
      ) : (
        <DatePicker
          onChange={handleChange}
          calendarIcon={''}
          clearIcon={''}
          format='dd/MM/yyyy'
          value={value}
          {...props}
        />
      )}
    </div>
  );
};

export default DateRangePickerField;
