import React from 'react';

import './PopularDecisionsCard.scss';

import LocationIcon from '../../../../../../assets/icons/location.svg';
import TimerIcon from '../../../../../../assets/icons/timer.svg';

const PopularDecisionsCard = ({
  image,
  sportType,
  location,
  title,
  price,
  currency,
  time,
  date,
}) => {
  return (
    <div className="PopularDecisionsCard">
      <img src={image} alt="" className="PopularDecisionsCard__img" />

      <div className="PopularDecisionsCard__sport">{sportType}</div>

      <div className="PopularDecisionsCard__bottom">
        <p className="PopularDecisionsCard__location">
          <img src={LocationIcon} alt="" />
          {location}
        </p>
        <h4 className="PopularDecisionsCard__title">{title}</h4>
        <p className="PopularDecisionsCard__price">
          {price} {currency}
        </p>

        <div className="PopularDecisionsCard__dates">
          <p className="PopularDecisionsCard__timer">
            <img
              src={TimerIcon}
              alt=""
              className="PopularDecisionsCard__timer__icon"
            />
            {time}
          </p>
          <div className="PopularDecisionsCard__date">{date}</div>
        </div>
      </div>
    </div>
  );
};

export default PopularDecisionsCard;
