import React from 'react';
import { colors } from '../Text/Colors/Colors';

export const EuroSymbol = ({ color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 15 16`}
      fill="none"
    >
      <path
        d="M0 10.7778V8.88889H1.95556L1.91111 8.62222C1.8963 8.51852 1.88889 8.31111 1.88889 8C1.88889 7.71852 1.8963 7.51852 1.91111 7.4L1.95556 7.11111H0V5.22222H2.33333C2.67407 4.25926 3.16296 3.39259 3.8 2.62222C5.22222 0.874074 7.1037 0 9.44444 0C10.9259 0 12.2889 0.407407 13.5333 1.22222C14.0667 1.59259 14.5185 2 14.8889 2.44444L13.3333 4C13.0963 3.68889 12.7704 3.39259 12.3556 3.11111C11.4963 2.51852 10.5259 2.22222 9.44444 2.22222C8.08148 2.22222 6.94074 2.72593 6.02222 3.73333C5.60741 4.16296 5.26667 4.65926 5 5.22222H9.55556V7.11111H4.4L4.35556 7.4C4.34074 7.51852 4.33333 7.71852 4.33333 8C4.33333 8.31111 4.34074 8.51852 4.35556 8.62222L4.4 8.88889H9.55556V10.7778H5C5.28148 11.3704 5.62222 11.8741 6.02222 12.2889C6.92593 13.2815 8.06667 13.7778 9.44444 13.7778C10.4963 13.7778 11.4815 13.4815 12.4 12.8889C12.8296 12.6074 13.1778 12.3111 13.4444 12L15 13.5556C14.6296 14 14.163 14.4074 13.6 14.7778C12.3556 15.5926 10.9704 16 9.44444 16C7.08889 16 5.20741 15.1333 3.8 13.4C3.14815 12.6148 2.65926 11.7407 2.33333 10.7778H0Z"
        fill={colors[color] || '#000'}
      />
    </svg>
  );
};

export const DollarSymbol = ({ color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 9 16"
      fill="none"
    >
      <path
        d="M0 12.4835L1.31868 11.1648C1.50623 11.411 1.75238 11.6454 2.05714 11.8681C2.737 12.337 3.51648 12.5714 4.3956 12.5714C5.15751 12.5714 5.75531 12.3956 6.18901 12.044C6.63443 11.6806 6.85714 11.241 6.85714 10.7253C6.85714 10.3033 6.69304 9.95751 6.36484 9.68791C6.04835 9.40659 5.64982 9.20147 5.16923 9.07253C4.68864 8.94359 4.16703 8.78535 3.6044 8.5978C3.04176 8.41026 2.52015 8.20513 2.03956 7.98242C1.55897 7.75971 1.15458 7.4022 0.826374 6.90989C0.50989 6.41758 0.351648 5.81392 0.351648 5.0989C0.351648 4.21978 0.650549 3.48132 1.24835 2.88352C1.84615 2.27399 2.6315 1.8989 3.6044 1.75824V0H5.36264V1.7055C6.23004 1.84615 6.9978 2.14506 7.66593 2.6022C7.99414 2.83663 8.25202 3.05348 8.43956 3.25275L7.12088 4.57143C6.90989 4.337 6.69304 4.14945 6.47033 4.00879C5.88425 3.62198 5.25128 3.42857 4.57143 3.42857C3.85641 3.42857 3.29377 3.59267 2.88352 3.92088C2.48498 4.23736 2.28571 4.63004 2.28571 5.0989C2.28571 5.52088 2.44396 5.87253 2.76044 6.15385C3.08864 6.42344 3.49304 6.62271 3.97363 6.75165C4.45421 6.88059 4.97582 7.03883 5.53846 7.22637C6.1011 7.41392 6.62271 7.61905 7.1033 7.84176C7.58388 8.06447 7.98242 8.42198 8.2989 8.91429C8.62711 9.40659 8.79121 10.0103 8.79121 10.7253C8.79121 11.6513 8.47472 12.4366 7.84176 13.0813C7.20879 13.7143 6.38242 14.1011 5.36264 14.2418V16H3.6044V14.2769C2.64322 14.1714 1.7348 13.8374 0.879121 13.2747C0.515751 13.0403 0.222711 12.7766 0 12.4835Z"
        fill={colors[color] || '#000'}
      />
    </svg>
  );
};
