// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PopularDecisions__cards {
  margin-top: 24px;
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}
.PopularDecisions__content {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/Main/components/PopularDecisions/PopularDecisions.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,aAAA;EACA,SAAA;EACA,eAAA;AAAJ;AAGE;EACE,aAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;AADJ","sourcesContent":[".PopularDecisions {\n  &__cards {\n    margin-top: 24px;\n    display: flex;\n    gap: 24px;\n    flex-wrap: wrap;\n  }\n\n  &__content {\n    display: flex;\n    flex-direction: column;\n    row-gap: 24px;\n    align-items: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
