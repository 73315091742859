import React, { useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Modal } from '@mui/material';
import CloseIcon from '../../assets/icons/CloseIcon';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import FormInput from '../FormInput/FormInput';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import OTPInput from 'react-otp-input';
import { Headline4 } from '../Text/Headlines/Headlines';
import { ButtonLText, DetailsText, MainText } from '../Text/Body/Bodies';
import { ButtonL } from '../Buttons/Buttons';
import { ForgotPasswordModal } from '../../store/modals/modals.store';
import './ForgotPassword.scss';
import {
  createNewPasswordOnForgotPassword,
  passwordSendCodeOnForgotPassword,
  сheckCodeOnForgotPassword,
} from '../../api/ForgotPassword/ForgotPassword.api';
import { userState } from '../../store/users/users.store';

const validationSchemas = [
  Yup.object().shape({
    email: Yup.string().email('Неправильный email').required('Введите email'),
  }),
  Yup.object().shape({
    otp: Yup.string().required('Введите код'),
  }),
  Yup.object().shape({
    password: Yup.string().required('Введите пароль'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Пароли должны совпадать')
      .required('Подтвердите пароль'),
  }),
];

const ForgotPassword = () => {
  const [ForgotPasswordModalState, setForgotPasswordModalState] =
    useRecoilState(ForgotPasswordModal);
  const swiperRef = useRef(null);
  const formikRef = useRef(null);
  const blockRef = useRef(null);
  const [otp, setOtp] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [user, setUser] = useRecoilState(userState);

  const updatePagination = index => {
    console.log('Updating to index:', index);
    setCurrentStep(index);
    document
      .querySelectorAll('.ForgotPassword__block-pagination__step')
      .forEach((step, stepIndex) => {
        step.classList.toggle('active', stepIndex <= index);
      });

    console.log(user);

    if (index === 1) {
      blockRef.current.classList.add('step2');
      blockRef.current.classList.remove('step3');
    } else if (index === 2) {
      blockRef.current.classList.add('step3');
      blockRef.current.classList.remove('step2');
    } else {
      blockRef.current.classList.remove('step2');
      blockRef.current.classList.remove('step3');
    }
  };

  async function sendCode(values) {
    await passwordSendCodeOnForgotPassword({
      email: values.email,
    });
  }

  async function validateCode(values) {
    const response = await сheckCodeOnForgotPassword({
      email: values.email,
      code: otp,
    });

    localStorage.setItem('LCK', JSON.stringify(response.access));

    setUser(response);
  }

  async function createNewPass(values) {
    await createNewPasswordOnForgotPassword({
      password1: values.password,
      password2: values.confirmPassword,
    });

    setForgotPasswordModalState(false);
  }

  const handleContinue = values => {
    if (formikRef.current) {
      if (currentStep === 0) {
        sendCode(values);
      } else if (currentStep === 1) {
        validateCode(values);
        swiperRef.current.slideNext();
        updatePagination(currentStep + 1);
      } else if (currentStep === 2) {
        createNewPass(values);
      }
      formikRef.current.submitForm();
    }
  };

  return (
    <Modal
      open={ForgotPasswordModalState}
      onClose={() => setForgotPasswordModalState(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="ForgotPassword">
        <div className="ForgotPassword__block" ref={blockRef}>
          <div className="ForgotPassword__block-title">
            <h2>Forgot password</h2>
            <button
              className="ForgotPassword__block-close"
              onClick={() => setForgotPasswordModalState(false)}
            >
              <CloseIcon />
            </button>
          </div>
          <div className="ForgotPassword__block-pagination">
            <button
              className="ForgotPassword__block-pagination__step active"
              disabled
            ></button>
            <button
              className="ForgotPassword__block-pagination__step"
              disabled
            ></button>
            <button
              className="ForgotPassword__block-pagination__step"
              disabled
            ></button>
          </div>
          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={validationSchemas[currentStep]}
            onSubmit={(values, actions) => {
              console.log('Form Submit, current step:', currentStep);
              if (currentStep < 2 && swiperRef.current) {
                swiperRef.current.slideNext();
                updatePagination(currentStep + 1);
              } else {
                console.log('Final submit:', values);
              }
              actions.setSubmitting(false);
            }}
            innerRef={formikRef}
          >
            {({ isValid, dirty, handleSubmit, values }) => (
              <Form>
                <Swiper
                  slidesPerView={1}
                  className="ForgotPassword__block-swiper"
                  onSlideChange={swiper => {
                    const newIndex = swiper.activeIndex;
                    console.log('onSlideChange to index:', newIndex);
                    if (newIndex !== currentStep) {
                      updatePagination(newIndex);
                    }
                  }}
                  allowTouchMove={false}
                  simulateTouch={false}
                  onSwiper={swiper => {
                    swiperRef.current = swiper;
                  }}
                >
                  <SwiperSlide>
                    <div className="ForgotPassword__block-swiper__form1">
                      <div className="ForgotPassword__block-swiper__form1-text">
                        <Headline4 variant={'dark900'}>
                          Reset password
                        </Headline4>
                        <MainText variant={'dark900'}>
                          Enter your email, and we'll send a password reset
                          link.
                        </MainText>
                      </div>
                      <Field
                        name="email"
                        component={FormInput}
                        label="Email"
                        inputLabel="Enter email"
                      />
                      <div className="ForgotPassword__form-buttons">
                        <ButtonL
                          fullWidth={true}
                          disabled={!(isValid && dirty)}
                          action={'button'}
                          onClick={() => handleContinue(values)}
                        >
                          <ButtonLText variant={'white'}>Sent</ButtonLText>
                        </ButtonL>
                        <p className="ForgotPassword__form-buttons-signUp">
                          Dont have an account yet?<span>Sign up</span>
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="ForgotPassword__block-swiper__form2">
                      <div className="ForgotPassword__block-swiper__form2-title">
                        <Headline4 variant={'dark900'} textAlign={'center'}>
                          Enter the code
                        </Headline4>
                        <DetailsText variant={'dark720'} textAlign={'center'}>
                          We have sent the code to your email. Please enter it.
                        </DetailsText>
                      </div>
                      <OTPInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        containerStyle={
                          'ForgotPassword__block-swiper__form2-inputs'
                        }
                        inputStyle={
                          'ForgotPassword__block-swiper__form2-inputs__input'
                        }
                        renderInput={props => <input {...props} />}
                      />
                      <div className="ForgotPassword__block-swiper__form2-controlls">
                        <ButtonL
                          fullWidth={true}
                          action={'button'}
                          onClick={() => handleContinue(values)}
                        >
                          <ButtonLText variant={'white'}>Continue</ButtonLText>
                        </ButtonL>
                      </div>
                      <p className="ForgotPassword__form-buttons-signUp">
                        Dont have an account yet?<span>Sign up</span>
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="ForgotPassword__block-swiper__form3">
                      <Field
                        name="password"
                        component={FormInput}
                        label="Create password"
                        inputLabel="Enter created password"
                        type="password"
                      />
                      <Field
                        name="confirmPassword"
                        component={FormInput}
                        label="Reenter the password"
                        inputLabel="Reenter created password"
                        type="password"
                      />
                      <div className="ForgotPassword__form-buttons">
                        <ButtonL
                          fullWidth={true}
                          disabled={!(isValid && dirty)}
                          action={'button'}
                          onClick={() => handleContinue(values)}
                        >
                          <ButtonLText variant={'white'}>Save</ButtonLText>
                        </ButtonL>
                      </div>
                      <p className="ForgotPassword__form-buttons-signUp">
                        Dont have an account yet?<span>Sign up</span>
                      </p>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default ForgotPassword;
