// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-to-date {
  display: flex;
  gap: 16px;
  align-items: center;
}
.date-to-date__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  width: 51px;
}
.date-to-date__item .MainText {
  font-weight: 600;
  font-size: 15px;
}
.date-to-date__middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 96px;
}`, "",{"version":3,"sources":["webpack://./src/components/DateToDate/DateToDate.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,mBAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,QAAA;EACA,WAAA;AACJ;AACI;EACE,gBAAA;EACA,eAAA;AACN;AAGE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;EACA,WAAA;AADJ","sourcesContent":[".date-to-date {\n  display: flex;\n  gap: 16px;\n  align-items: center;\n\n  &__item {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 4px;\n    width: 51px;\n\n    .MainText {\n      font-weight: 600;\n      font-size: 15px;\n    }\n  }\n\n  &__middle {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 16px;\n    width: 96px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
