import React from 'react';

import './WhatWeProvideCard.scss';

const WhatWeProvideCard = ({ image, isSoon, subtitle }) => {
  return (
    <div className="WhatWeProvideCard">
      {isSoon && (
        <div className="WhatWeProvideCard__soon">
          <span>Soon</span>
        </div>
      )}

      <img src={image} alt="" className="WhatWeProvideCard__image" />

      <h2 className="WhatWeProvide__subtitle">{subtitle}</h2>
    </div>
  );
};

export default WhatWeProvideCard;
