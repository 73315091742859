import { backend } from './../index';

export const getAboutUs = async () => {
  try {
    const response = await backend.get('/abouts/get_about_us/');
    return response;
  } catch (error) {
    console.error('Error fetching about us:', error);
    throw error;
  }
};
