import React from 'react';

import './DestinationsCard.scss';
import IconButton from '../../../../../../components/Buttons/IconButton/IconButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';

const DestinationsCard = ({ image, price, title, link }) => {
  return (
    <div className="DestinationsCard" style={{ background: `url(${image}) no-repeat center/cover` }}>
      <h2 className="DestinationsCard__title">{title}</h2>

      <div className="DestinationsCard__priceBlock">
        <div className="DestinationsCard__priceBlock-left">
          <p className="DestinationsCard__priceBlock-left__from">from</p>
          <h6 className="DestinationsCard__priceBlock-left__price">
            {price} {'  '}
            <span>€</span>
          </h6>
        </div>

        <Link to={link} className="DestinationsCard__priceBlock-btn">
          <IconButton icon={<ArrowForwardIcon />} />
        </Link>
      </div>
    </div>
  );
};

export default DestinationsCard;
