import React from 'react';

import './PageLayout.scss';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const PageLayout = ({ children }) => {
  return (
    <div>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default PageLayout;
