import { backend } from './../index';

const endpoint = '/reviews';

export const getReviews = async data => {
  try {
    const response = await backend.get(`${endpoint}/`, data);
    return response;
  } catch (error) {
    console.error('Error fetching reviews:', error);
    throw error;
  }
};

export const createReviews = async data => {
  try {
    const response = await backend.post(`${endpoint}/`, data);
    return response;
  } catch (error) {
    console.error('Error creating reviews:', error);
    throw error;
  }
};

export const createReviewAnswer = async data => {
  try {
    const response = await backend.post(`${endpoint}/answer/`, data);
    return response;
  } catch (error) {
    console.error('Error creating review answer:', error);
    throw error;
  }
};

export const getMyReviews = async data => {
  try {
    const response = await backend.get(`${endpoint}/my`, data);
    return response;
  } catch (error) {
    console.error('Error fetching my reviews:', error);
    throw error;
  }
};

export const createNewReview = async data => {
  try {
    const response = await backend.post(`${endpoint}/new`, data);
    return response.data;
  } catch (error) {
    console.error('Error creating new review:', error);
    throw error;
  }
};

export const setReviewDislike = async (id, data) => {
  try {
    const response = await backend.post(`${endpoint}/${id}/set_dislike`, data);
    return response.data;
  } catch (error) {
    console.error('Error setting dislike on review:', error);
    throw error;
  }
};

export const setReviewLike = async (id, data) => {
  try {
    const response = await backend.post(`${endpoint}/${id}/set_like`, data);
    return response.data;
  } catch (error) {
    console.error('Error setting like on review:', error);
    throw error;
  }
};
