import React from 'react';
import './Destinations.scss';
import { Headline2 } from '../../../../components/Text/Headlines/Headlines';
import DestinationsCard from './components/DestinationsCard/DestinationsCard';
import PageContainer from '../../../../components/PageContainer/PageContainer';

const Destinations = ({ destinations }) => {
  return (
    destinations?.length > 0 && (
      <div className="Destinations">
        <PageContainer>
          <Headline2>Destinations</Headline2>

          <div className="Destinations__cards">
            {destinations.map(item => (
              <DestinationsCard
                key={item.id}
                image={item.icon}
                title={item.name}
                price={item.min_price}
                link={`/destinations/${item?.id}`}
              />
            ))}
          </div>
        </PageContainer>
      </div>
    )
  );
};

export default Destinations;
