import { Skeleton, Typography } from '@mui/material';
import React from 'react';
import { Headline4 } from '../../../../components/Text/Headlines/Headlines';

const BikeCard = ({ bike, loading }) => {
  return (
    <div className="TourDetail__included__item">
      {loading ? (
        <Skeleton variant="rectangular" width={384} height={243}></Skeleton>
      ) : (
        <img src={bike.image} alt="" />
      )}
      <Typography className="TourDetail__included__item__title">
        {loading ? <Skeleton></Skeleton> : <Headline4>{bike.name}</Headline4>}
      </Typography>
    </div>
  );
};

export default BikeCard;
