import React from 'react';

import './GhostButton.scss'

const GhostButton = ({text, action, type}) => {
    return (
        <button className={'ghost-button'} onClick={action} type={type}>{text}</button>
    );
};

export default GhostButton;