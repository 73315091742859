import React from 'react';

import './HowItWorksCard.scss';

const HowItWorksCard = ({ icon, title, description }) => {
  return (
    <div className="HowItWorksCard">
      <img src={icon} alt="" className="HowItWorksCard__icon" />
      <h2 className="HowItWorksCard__title">{title}</h2>
      <p className="HowItWorksCard__description">{description}</p>
    </div>
  );
};

export default HowItWorksCard;
