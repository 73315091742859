import React from 'react';

import './OurBlogCard.scss';
import { useNavigate } from 'react-router-dom';
import { Headline4 } from '../../../../../../components/Text/Headlines/Headlines';
import {
  ButtonMText,
  DetailsText,
} from '../../../../../../components/Text/Body/Bodies';
import { ButtonM } from '../../../../../../components/Buttons/Buttons';
import { Skeleton } from '@mui/material';

const OurBlogCard = ({ item, loading }) => {
  const navigate = useNavigate();

  function formatDate(isoDateString) {
    const date = new Date(isoDateString);

    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-GB', options);

    return formattedDate;
  }

  // Функция для очистки HTML из строки
  const stripHtmlTags = html => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;
    return tempElement.innerText || tempElement.textContent;
  };

  const handleNavigate = () => {
    navigate(`/news/${item?.id}`);
  };

  return loading ? (
    <Skeleton variant="rounded" width={282} height={422}></Skeleton>
  ) : (
    <div className="OurBlogCard" onClick={() => handleNavigate()}>
      <img src={item?.icon} alt="" className="OurBlogCard__img" />

      <div className="OurBlogCard__bottom">
        <p className="OurBlogCard__date">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.66699 1.6665V4.1665"
              stroke="#747B8A"
              stroke-width="1.2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.333 1.6665V4.1665"
              stroke="#747B8A"
              stroke-width="1.2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.91699 7.5752H17.0837"
              stroke="#747B8A"
              stroke-width="1.2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.5 7.08317V14.1665C17.5 16.6665 16.25 18.3332 13.3333 18.3332H6.66667C3.75 18.3332 2.5 16.6665 2.5 14.1665V7.08317C2.5 4.58317 3.75 2.9165 6.66667 2.9165H13.3333C16.25 2.9165 17.5 4.58317 17.5 7.08317Z"
              stroke="#747B8A"
              stroke-width="1.2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.0791 11.4167H13.0866"
              stroke="#747B8A"
              stroke-width="1.8"
              stroke-linecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.0791 13.9167H13.0866"
              stroke="#747B8A"
              stroke-width="1.8"
              stroke-linecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.99607 11.4167H10.0036"
              stroke="#747B8A"
              stroke-width="2"
              stroke-linecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.99607 13.9167H10.0036"
              stroke="#747B8A"
              stroke-width="1.8"
              stroke-linecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.91209 11.4167H6.91957"
              stroke="#747B8A"
              stroke-width="1.8"
              stroke-linecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.91209 13.9167H6.91957"
              stroke="#747B8A"
              stroke-width="1.8"
              stroke-linecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {formatDate(item?.created_at)}
        </p>

        <Headline4 variant={'dark900'}>{item?.title}</Headline4>

        <DetailsText variant={'dark720'}>
          {stripHtmlTags(item?.text)}
        </DetailsText>

        <ButtonM type={'outlined'} fullWidth>
          <ButtonMText variant={'jungle500'}>Read</ButtonMText>
        </ButtonM>
      </div>
    </div>
  );
};

export default OurBlogCard;
