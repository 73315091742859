import { backend } from './../index';

const endpoint = '/auth';

export const logout = async data => {
  try {
    const response = await backend.post(`${endpoint}/logout/`, data);
    localStorage.clear();
    return response;
  } catch (error) {
    console.error('Error logout:', error);
    throw error;
  }
};
