// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/components/PageContainer/PageContainer.scss"],"names":[],"mappings":"AACA;EACI,iBAAA;EACA,WAAA;EACA,cAAA;AAAJ","sourcesContent":["\n.container {\n    max-width: 1200px;\n    width: 100%;\n    margin: 0 auto;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
