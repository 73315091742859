import './Navbar.scss';
import React from 'react';
import NavbarItem from './NavbarItem';
import { Logout } from 'iconsax-react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { userState } from '../../../../store/users/users.store';
import { ButtonS } from '../../../../components/Buttons/Buttons';
import { loginModal } from '../../../../store/modals/modals.store';
import { ButtonSText } from '../../../../components/Text/Body/Bodies';
import GhostButton from '../../../../components/Buttons/GhostButton/GhostButton';
import { logout } from '../../../../api/Logout/Logout.api';

const Navbar = () => {
  const setLoginModal = useSetRecoilState(loginModal);
  const [User, setUser] = useRecoilState(userState);

  const logoutHandler = () => {
    logout({ RefreshToken: localStorage.getItem('refresh_token') }).then(() => {
      localStorage.removeItem('refresh_token'); // Очищаем токен
      localStorage.removeItem('access_token'); // Очищаем access токен, если есть
      setUser({}); // Очищаем состояние пользователя
    });
  };

  return (
    <nav className="Navbar">
      <div className="container">
        <div className="Navbar__links">
          <NavbarItem
            className={({ isActive }) =>
              isActive ? 'Navbar__links-item active' : 'Navbar__links-item'
            }
            to={'/'}
          >
            Home
          </NavbarItem>
          <NavbarItem
            className={({ isActive }) =>
              isActive ? 'Navbar__links-item active' : 'Navbar__links-item'
            }
            to={'/destinations'}
          >
            Destinations
          </NavbarItem>
          <NavbarItem
            className={({ isActive }) =>
              isActive ? 'Navbar__links-item active' : 'Navbar__links-item'
            }
            to={'/popular-decisions'}
          >
            Popular decision
          </NavbarItem>
          <NavbarItem
            className={({ isActive }) =>
              isActive ? 'Navbar__links-item active' : 'Navbar__links-item'
            }
            to={'/news'}
          >
            Blog
          </NavbarItem>
          <NavbarItem
            className={({ isActive }) =>
              isActive ? 'Navbar__links-item active' : 'Navbar__links-item'
            }
            to={'/about-us'}
          >
            About us
          </NavbarItem>
        </div>

        <div className="Navbar__buttons">
          {!User?.id ? (
            <GhostButton text={'Log in'} action={() => setLoginModal(true)} />
          ) : (
            <ButtonS
              type={'tertiary'}
              className={'logoutButton'}
              onClick={() => logoutHandler()}
            >
              <Logout size="24" color="#007C6E" />
              <ButtonSText variant={'jungle500'}>{User?.email}</ButtonSText>
            </ButtonS>
          )}
          <ButtonS type={'outlined'} disabled={true}>
            <ButtonSText variant={'jungle500'}>Find Tour</ButtonSText>
          </ButtonS>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
