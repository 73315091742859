import React from 'react';
import './ToursCard.scss';
import {
  Headline5,
  Headline6,
} from '../../../../components/Text/Headlines/Headlines';
import {
  CaptionsText,
  DetailsText,
} from '../../../../components/Text/Body/Bodies';
import { ButtonS } from '../../../../components/Buttons/Buttons';
import { Skeleton } from '@mui/material';
import NotFound from './../../../../assets/images/NotFound.png';
import { EuroSymbol } from '../../../../components/Ex/Ex';

const ToursCard = ({ tour, country, loading }) => {
  return (
    <div className="ToursCard">
      <div className="ToursCard__top">
        <div className="ToursCard__top-image">
          {loading ? (
            <Skeleton variant="rounded" width={180} height={144}></Skeleton>
          ) : (
            <>
              <img src={tour?.image || NotFound} alt="TourImage" />
              <div className="ToursCard__top-image__type">
                <Headline6>{tour?.tour_type?.title}</Headline6>
              </div>
            </>
          )}
        </div>
        <div className="ToursCard__top-info">
          {loading ? (
            <Skeleton
              variant="text"
              sx={{ fontSize: '16px', lineHeight: '24px' }}
              width={'60%'}
            ></Skeleton>
          ) : (
            <Headline5>{tour?.title}</Headline5>
          )}
          <div className="ToursCard__top-info__stats">
            <div className="ToursCard__top-info__stats-stat">
              <CaptionsText variant={'dark720'}>Level:</CaptionsText>
              {loading ? (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: '14px', lineHeight: '20px' }}
                  width={'60%'}
                ></Skeleton>
              ) : (
                <Headline6 variant={'dark900'}>{tour?.level?.title}</Headline6>
              )}
            </div>
            <div className="ToursCard__top-info__stats-stat">
              <CaptionsText variant={'dark720'}>Duration:</CaptionsText>
              {loading ? (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: '14px', lineHeight: '20px' }}
                  width={'60%'}
                ></Skeleton>
              ) : (
                <Headline6 variant={'dark900'}>
                  {tour?.duration_day} days/{tour?.duration_night} nights
                </Headline6>
              )}
            </div>
            <div className="ToursCard__top-info__stats-stat">
              <CaptionsText variant={'dark720'}>Hotel level:</CaptionsText>
              {loading ? (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: '14px', lineHeight: '20px' }}
                  width={'60%'}
                ></Skeleton>
              ) : (
                <Headline6 variant={'dark900'}>нет на бэки</Headline6>
              )}
            </div>
            <div className="ToursCard__top-info__stats-stat">
              <CaptionsText variant={'dark720'}>Trip style:</CaptionsText>
              {loading ? (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: '14px', lineHeight: '20px' }}
                  width={'60%'}
                ></Skeleton>
              ) : (
                <Headline6 variant={'dark900'}>
                  {tour?.tour_type.title}
                </Headline6>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="ToursCard__bottom">
        {loading ? (
          <Skeleton variant="rectangular" width={540} height={80}></Skeleton>
        ) : (
          <DetailsText variant={'dark720'}>{tour?.description}</DetailsText>
        )}
        <div className="ToursCard__bottom-controlls">
          <div className="ToursCard__bottom-controlls__cost">
            <CaptionsText>From:</CaptionsText>
            {loading ? (
              <Skeleton
                variant="text"
                sx={{ fontSize: '14px', lineHeight: '20px' }}
                width={'400%'}
              ></Skeleton>
            ) : (
              <Headline5>
                {tour?.min_price} <EuroSymbol size={13} color={'dark540'} />
              </Headline5>
            )}
          </div>
          <ButtonS
            to={`/destinations/${country?.name?.toLowerCase()}/${tour?.id}`}
            variant={'jungle500'}
            disabled={loading}
            iconRightShow={true}
            type={'outlined'}
          >
            View Tour
          </ButtonS>
        </div>
      </div>
    </div>
  );
};

export default ToursCard;
