import React from 'react';

import './BenefitsCard.scss';

const BenefitsCard = ({ icon, title, description }) => {
  return (
    <div className="BenefitsCard">
      <img src={icon} alt="" className="BenefitsCard__icon" />
      <h2 className="BenefitsCard__title">{title}</h2>
      <p className="BenefitsCard__description">{description}</p>
    </div>
  );
};

export default BenefitsCard;
