import React from 'react';
import { makeStyles } from '@mui/styles';
import { Calendar } from 'iconsax-react';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import DateRangePickerField from '../../../../components/DateRangePicker/DateRangePickerField';
import { format } from 'date-fns'; // Import date-fns for date formatting
import { Skeleton } from '@mui/material';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#F5F6F7',
    borderRadius: 12,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#F5F6F7',
    height: '58px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 10px',
    '&:hover': {
      backgroundColor: '#EDEFF2',
    },
    '& input::placeholder': {
      color: '#4E5666',
    },
    '&.Mui-focused': {
      borderColor: '#007C6E',
      borderWidth: 1,
      borderStyle: 'solid',
      backgroundColor: '#F5F6F7',
    },
  },
  label: {
    color: '#4E5666',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    '&.Mui-focused': {
      color: '#4E5666',
    },
  },
  underline: {
    '&:before': {
      borderBottom: 'none',
      display: 'none',
    },
    '&:after': {
      borderBottom: 'none',
      display: 'none',
    },
  },
}));

const DateField = ({
  field,
  label,
  form,
  inputType,
  selectRange = false,
  loading,
  backgroundColor,
  ...props
}) => {
  const classes = useStyles();

  const handleChange = newDate => {
    // Format the date to 'YYYY-MM-DD' before setting it to Formik
    const formattedDate = newDate ? format(newDate, 'yyyy-MM-dd') : '';
    form.setFieldValue(field.name, formattedDate);
  };

  return (
    <div className="DateField">
      {label && (
        <p className={classes.label}>
          {loading ? <Skeleton width={'80%'}></Skeleton> : label}
        </p>
      )}
      {loading ? (
        <Skeleton
          variant="rounded"
          sx={{ maxWidth: '356px', width: '100%', height: '56px' }}
        ></Skeleton>
      ) : (
        <div className={classes.root} style={{backgroundColor: backgroundColor || '#F5F6F7'}}>
          <DateRangePickerField
            selectRange={selectRange}
            onChange={handleChange}
            value={field.value}
            {...props}
          />
          <Calendar color="#9b9d9d" className="DateField__icon" />
        </div>
      )}
    </div>
  );
};

export default DateField;
