// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/images/mainImage.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TopComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 32px;
  padding-top: 100px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
  height: 520px;
}
.TopComponent__title {
  font-family: Mulish, sans-serif;
  font-size: 44px;
  font-weight: 700;
  line-height: 56px;
  text-align: center;
  color: #fff;
}
.TopComponent__description {
  font-family: Mulish, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/pages/Main/components/TopComponent/TopComponent.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;EACA,kBAAA;EACA,mDAAA;EACA,4BAAA;EACA,sBAAA;EACA,aAAA;AACF;AACE;EACE,+BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,WAAA;AACJ;AAEE;EACE,+BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,WAAA;AAAJ","sourcesContent":[".TopComponent {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  row-gap: 32px;\n  padding-top: 100px;\n  background: url('../../../../assets/images/mainImage.png');\n  background-repeat: no-repeat;\n  background-size: cover;\n  height: 520px;\n\n  &__title {\n    font-family: Mulish, sans-serif;\n    font-size: 44px;\n    font-weight: 700;\n    line-height: 56px;\n    text-align: center;\n    color: #fff;\n  }\n\n  &__description {\n    font-family: Mulish, sans-serif;\n    font-size: 18px;\n    font-weight: 400;\n    line-height: 26px;\n    text-align: center;\n    color: #fff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
