// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputDrop {
  display: flex;
  flex-direction: column;
  gap: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/InputDrop/InputDrop.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,QAAA;AACJ","sourcesContent":[".InputDrop {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
