// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HowItWorksCard {
  padding: 16px 32px 16px 0;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
.HowItWorksCard__icon {
  width: fit-content;
}
.HowItWorksCard__title {
  font-family: Mulish, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  color: #1d2026;
}
.HowItWorksCard__description {
  font-family: Mulish, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #4e5666;
}`, "",{"version":3,"sources":["webpack://./src/pages/AboutUs/components/HowItWorksCard/HowItWorksCard.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,aAAA;EACA,sBAAA;EAEA,aAAA;AAAF;AAEE;EACE,kBAAA;AAAJ;AAGE;EACE,+BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AADJ;AAIE;EACE,+BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AAFJ","sourcesContent":[".HowItWorksCard {\n  padding: 16px 32px 16px 0;\n  display: flex;\n  flex-direction: column;\n\n  row-gap: 24px;\n\n  &__icon {\n    width: fit-content;\n  }\n\n  &__title {\n    font-family: Mulish, sans-serif;\n    font-size: 20px;\n    font-weight: 700;\n    line-height: 28px;\n    text-align: left;\n    color: #1d2026;\n  }\n\n  &__description {\n    font-family: Mulish, sans-serif;\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n    text-align: left;\n    color: #4e5666;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
