import React from 'react';
import { useField } from 'formik';
import { Switch, FormControlLabel, FormGroup, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Headline5 } from '../../../../components/Text/Headlines/Headlines';
import { CaptionsText } from '../../../../components/Text/Body/Bodies';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiSwitch-root': {
      padding: 8,
      '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
          color: '#fff',
          '& + .MuiSwitch-track': {
            backgroundColor: '#007C6E',
            opacity: 1,
          },
        },
      },
      '& .MuiSwitch-track': {
        backgroundColor: '#EBEDF5',
        borderRadius: 40,
      },
    },
    '& .MuiFormControlLabel-label': {
      color: '#4E5666',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
}));

const SwitchField = ({
  field,
  form,
  inputLabel,
  label,
  description,
  loading,
  ...props
}) => {
  const classes = useStyles();
  const { name, value } = field;
  const { setFieldValue } = form;

  return (
    <div className="SwitchField">
      {label && (
        <Headline5 variant={'dark900'}>
          {loading ? (
            <Skeleton
              sx={{ fontSize: '16px', lineHeight: '24px', width: '70%' }}
            ></Skeleton>
          ) : (
            label
          )}
        </Headline5>
      )}
      {loading ? (
        <Skeleton variant="rounded" width={58} height={38}></Skeleton>
      ) : (
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                {...field}
                checked={value}
                size={'medium'}
                onChange={event => {
                  setFieldValue(name, event.target.checked);
                }}
                {...props}
              />
            }
            label={inputLabel}
            className={classes.root}
          />
        </FormGroup>
      )}
      {description && (
        <CaptionsText className="SwitchField__description">
          {loading ? (
            <Skeleton
              sx={{ fontSize: '12px', lineHeight: '16px', width: '80%' }}
            ></Skeleton>
          ) : (
            description
          )}
        </CaptionsText>
      )}
    </div>
  );
};

export default SwitchField;
