// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Destinations__cards {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Main/components/Destinations/Destinations.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,8BAAA;EACA,gBAAA;AAAJ","sourcesContent":[".Destinations {\n  &__cards {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
