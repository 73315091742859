import React from 'react';

import './NewsDetailsCards.scss';

import Calendar from '../../../assets/icons/calendar.svg';

const NewsDetailsCards = ({ image, date, text }) => {
  return (
    <div className="NewsDetailsCards">
      <img src={image} className="NewsDetailsCards__image" alt="" />

      <div className="NewsDetailsCards__wrapper">
        <p className="NewsDetailsCards__date">
          <img src={Calendar} alt="" /> {date}
        </p>

        <p className="NewsDetailsCards__text">{text}</p>
      </div>
    </div>
  );
};

export default NewsDetailsCards;
