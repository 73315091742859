import React from 'react';

import './IconButton.scss';
const IconButton = ({ icon, action, type }) => {
  return (
    <button className="icon-button" onClick={action} type={type}>
      {icon}
    </button>
  );
};

export default IconButton;
