// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CountryCard {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 282px;
  width: 100%;
  height: 331px;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
}
.CountryCard img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  filter: brightness(70%);
  transition: 0.7s all;
}
.CountryCard:hover img {
  transform: scale(1.05);
}`, "",{"version":3,"sources":["webpack://./src/pages/DestinationsPage/components/CountryCards/CountryCard.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,WAAA;EACA,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;AACF;AACE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,WAAA;EACA,uBAAA;EACA,oBAAA;AACJ;AAGI;EACE,sBAAA;AADN","sourcesContent":[".CountryCard {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  max-width: 282px;\n  width: 100%;\n  height: 331px;\n  position: relative;\n  border-radius: 16px;\n  overflow: hidden;\n\n  img {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n    z-index: -1;\n    filter: brightness(70%);\n    transition: .7s all;\n  }\n\n  &:hover {\n    img {\n      transform: scale(1.05);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
