import AboutUs from '../pages/AboutUs/AboutUs';
import DestinationsPage from '../pages/DestinationsPage/DestinationsPage';
import Main from '../pages/Main/Main';
import NewsDetails from '../pages/NewsDetails/NewsDetails';
import NewsPage from '../pages/NewsPage/NewsPage';
import PopularDecisionsPage from '../pages/PopularDecisionsPage/PopularDecisionsPage';
import DestinationDetail from '../pages/DestinationDetail/DestinationDetail';
import TourDetail from '../pages/TourDetail/TourDetail';
import TourForm from '../pages/TourForm/TourForm';

const routes = [
  {
    path: '/',
    element: <Main />,
  },
  {
    path: '/destinations',
    element: <DestinationsPage />,
  },
  {
    path: '/destinations/:id',
    element: <DestinationDetail />,
  },
  {
    path: '/destinations/:country/:tourName',
    element: <TourDetail />,
  },
  {
    path: '/popular-decisions',
    element: <PopularDecisionsPage />,
  },
  {
    path: '/news',
    element: <NewsPage />,
  },
  {
    path: '/news/:id',
    element: <NewsDetails />,
  },
  {
    path: '/about-us',
    element: <AboutUs />,
  },
  {
    path: '/tour-form/:id',
    element: <TourForm />,
  },
];

export default routes;
