import React, { useEffect, useState } from 'react';

import './PopularDecisionsPage.scss';

import FilterSettings from '../../assets/icons/settings.svg';
import StyledTextField from '../../components/StyledTextField/StyledTextField';
import { Headline2 } from '../../components/Text/Headlines/Headlines';
import ToursCard from '../DestinationDetail/components/ToursCard/ToursCard';
import { getPopularDecisions } from '../../api/Tours/Tours.api';
import Tours from '../../components/Tours/Tours';

const PopularDecisionsPage = () => {
  const [decisions, setDecisions] = useState([]);

  async function getDecisionsTour() {
    const response = await getPopularDecisions();

    setDecisions(response);
  }

  useEffect(() => {
    getDecisionsTour();
  }, []);

  return (
    <div className="PopularDecisionsPage">
      <div className="container">
        {/* <div className="PopularDecisionsPage__content">
          <Headline2>Find the best decision</Headline2>
          <div className="PopularDecisionsPage__filters">
            <div className="PopularDecisionsPage__filters__options">
              <img
                src={FilterSettings}
                className="PopularDecisionsPage__filters__options__image"
                alt="Filter settings"
              />
              <p className="PopularDecisionsPage__filters__options__text">
                Filters by
              </p>
            </div>

            <div className="PopularDecisionsPage__filters__fields">
              <div className="PopularDecisionsPage__filters__fields__item">
                <p className="PopularDecisionsPage__filters__fields__item__label">
                  Select destination
                </p>
                <StyledTextField variant={'outlined'} />
              </div>
              <div className="PopularDecisionsPage__filters__fields__item">
                <p className="PopularDecisionsPage__filters__fields__item__label">
                  Select date
                </p>
                <StyledTextField variant={'outlined'} />
              </div>
              <div className="PopularDecisionsPage__filters__fields__item">
                <p className="PopularDecisionsPage__filters__fields__item__label">
                  Type of tour
                </p>
                <StyledTextField variant={'outlined'} />
              </div>
            </div>
          </div>

          <div className="PopularDecisionsPage__cards">
            {decisions?.map((tour, index) => (
              <ToursCard key={index} tour={tour} />
            ))}
          </div>
        </div> */}
        {decisions && <Tours tours={decisions} />}
      </div>
    </div>
  );
};

export default PopularDecisionsPage;
