// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ghost-button {
  padding: 8px 32px;
  border-radius: 8px;
  background: none;
  color: #007C6E;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Buttons/GhostButton/GhostButton.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,YAAA;AACF","sourcesContent":[".ghost-button {\n  padding: 8px 32px;\n  border-radius: 8px;\n  background: none;\n  color: #007C6E;\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n  text-align: left;\n  border: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
