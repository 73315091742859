// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RegularText {
  font-weight: 300;
  letter-spacing: 0;
}
.RegularText.ImportantText {
  font-size: 18px;
  line-height: 26px;
}
.RegularText.MainText {
  font-size: 16px;
  line-height: 24px;
}
.RegularText.DetailsText {
  font-size: 14px;
  line-height: 20px;
}
.RegularText.CaptionsText {
  font-size: 12px;
  line-height: 16px;
}

.MediumText {
  font-weight: 500;
  letter-spacing: 0;
}
.MediumText.ButtonLText {
  font-size: 18px;
  line-height: 24px;
}
.MediumText.ButtonMText {
  font-size: 16px;
  line-height: 24px;
}
.MediumText.ButtonSText {
  font-size: 14px;
  line-height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Text/Body/Bodies.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,iBAAA;AACF;AACE;EACE,eAAA;EACA,iBAAA;AACJ;AAEE;EACE,eAAA;EACA,iBAAA;AAAJ;AAGE;EACE,eAAA;EACA,iBAAA;AADJ;AAIE;EACE,eAAA;EACA,iBAAA;AAFJ;;AAMA;EACE,gBAAA;EACA,iBAAA;AAHF;AAKE;EACE,eAAA;EACA,iBAAA;AAHJ;AAME;EACE,eAAA;EACA,iBAAA;AAJJ;AAOE;EACE,eAAA;EACA,iBAAA;AALJ","sourcesContent":[".RegularText {\n  font-weight: 300;\n  letter-spacing: 0;\n\n  &.ImportantText {\n    font-size: 18px;\n    line-height: 26px;\n  }\n\n  &.MainText {\n    font-size: 16px;\n    line-height: 24px;\n  }\n\n  &.DetailsText {\n    font-size: 14px;\n    line-height: 20px;\n  }\n\n  &.CaptionsText {\n    font-size: 12px;\n    line-height: 16px;\n  }\n}\n\n.MediumText {\n  font-weight: 500;\n  letter-spacing: 0;\n\n  &.ButtonLText {\n    font-size: 18px;\n    line-height: 24px;\n  }\n\n  &.ButtonMText {\n    font-size: 16px;\n    line-height: 24px;\n  }\n\n  &.ButtonSText {\n    font-size: 14px;\n    line-height: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
