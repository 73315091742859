import { backend } from './../index';

const endpoint = '/schedulers/';

export const getAllSchedules = async params => {
  try {
    const response = await backend.get(endpoint, { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching all schedules:', error);
    throw error;
  }
};

export const getUpcomingTours = async params => {
  try {
    const response = await backend.get(`${endpoint}day_to_day/`, { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching upcoming tours:', error);
    throw error;
  }
};

export const getScheduleById = async (id, params) => {
  try {
    const response = await backend.get(`${endpoint}${id}/`, { params });
    return response.data;
  } catch (error) {
    console.error(`Error fetching schedule with ID ${id}:`, error);
    throw error;
  }
};
