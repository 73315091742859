import { backend } from '../index';

const endpoint = '/tours/';

export const getAllTourTypes = async params => {
  try {
    const response = await backend.get(endpoint, { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching all tour types:', error);
    throw error;
  }
};

export const getPopularDecisions = async params => {
  try {
    const response = await backend.get(`${endpoint}popular_decisions/`, {
      params,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching popular decisions:', error);
    throw error;
  }
};

export const getTourById = async (id, params) => {
  try {
    const response = await backend.get(`${endpoint}${id}/`, { params });
    return response.data;
  } catch (error) {
    console.error(`Error fetching tour with ID ${id}:`, error);
    throw error;
  }
};
