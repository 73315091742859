import React from 'react';

const SignsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.74874 2.23283C7.74874 1.27581 8.52455 0.5 9.48156 0.5C10.4386 0.5 11.2144 1.27581 11.2144 2.23283V20.3947H13.9856C14.2908 20.3947 14.5382 20.6422 14.5382 20.9474C14.5382 21.2526 14.2908 21.5 13.9856 21.5H4.88121C4.576 21.5 4.32858 21.2526 4.32858 20.9474C4.32858 20.6422 4.576 20.3947 4.88121 20.3947H7.74874V2.23283ZM8.854 20.3947H10.1091V2.23283C10.1091 1.88623 9.82816 1.60526 9.48156 1.60526C9.13497 1.60526 8.854 1.88623 8.854 2.23283V20.3947Z"
        fill="#747B8A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.85676 2.76945C2.00653 2.61219 2.2142 2.52319 2.43136 2.52319H8.06052C8.49875 2.52319 8.854 2.87845 8.854 3.31668V5.86976C8.854 6.30799 8.49875 6.66325 8.06052 6.66325H2.43136C2.2142 6.66325 2.00653 6.57424 1.85676 6.41699L0.641012 5.14045C0.349154 4.834 0.349155 4.35244 0.641012 4.04599L1.85676 2.76945ZM2.56498 3.62846L1.64615 4.59322L2.56498 5.55798H7.74874V3.62846H2.56498Z"
        fill="#747B8A"
      />
      <path
        d="M1.64615 4.59322L2.56498 3.62846H7.74874V5.55798H2.56498L1.64615 4.59322Z"
        fill="#747B8A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.143 5.80422C15.9932 5.64696 15.7855 5.55796 15.5684 5.55796H10.9026C10.4644 5.55796 10.1091 5.91322 10.1091 6.35145V8.90453C10.1091 9.34276 10.4644 9.69801 10.9026 9.69801H15.5684C15.7855 9.69801 15.9932 9.60901 16.143 9.45176L17.3587 8.17522C17.6506 7.86877 17.6506 7.3872 17.3587 7.08075L16.143 5.80422ZM15.4347 6.66322L16.3536 7.62799L15.4347 8.59275H11.2144V6.66322H15.4347Z"
        fill="#747B8A"
      />
      <path
        d="M16.3536 7.62799L15.4347 6.66322H11.2144V8.59275H15.4347L16.3536 7.62799Z"
        fill="#747B8A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.74705 9.17623C4.89681 9.01898 5.10448 8.92997 5.32164 8.92997H8.06052C8.49875 8.92997 8.85401 9.28523 8.85401 9.72346V12.2765C8.85401 12.7148 8.49875 13.07 8.06052 13.07H5.32164C5.10448 13.07 4.89681 12.981 4.74705 12.8238L3.5313 11.5472C3.23944 11.2408 3.23944 10.7592 3.5313 10.4528L4.74705 9.17623ZM5.45526 10.0352L4.53644 11L5.45526 11.9648H7.74875V10.0352H5.45526Z"
        fill="#747B8A"
      />
      <path
        d="M4.53644 11L5.45526 10.0352H7.74875V11.9648H5.45526L4.53644 11Z"
        fill="#747B8A"
      />
    </svg>
  );
};

export default SignsIcon;
