import { backend } from '../index';

const endpoint = '/users/';

export const setUserAvatar = async data => {
  try {
    const response = await backend.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.error('Error setting user avatar:', error);
    throw error;
  }
};

export const patchUserData = async (id, data, setNewUserData) => {
  try {
    const response = await backend.patch(endpoint + id + '/', data);
    await setNewUserData(response.data);
    localStorage.removeItem('user');
    localStorage.setItem('user', JSON.stringify(response.data));
    return response.data;
  } catch (error) {
    console.error('Error patching user data:', error);
    throw error;
  }
};
