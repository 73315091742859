import React from 'react';

import './OutlinedButton.scss';

const OutlinedButton = ({ text, action, type, fullWidth }) => {
  return (
    <button
      className={'outlined-button'}
      onClick={action}
      style={fullWidth ? { width: '100%', textAlign: 'center' } : {}}
      type={type}
    >
      {text}
    </button>
  );
};

export default OutlinedButton;
