import React from 'react';

const BicycleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="14"
      viewBox="0 0 22 14"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.74444 6.01444C3.01072 6.01444 1.60526 7.4199 1.60526 9.15362C1.60526 10.8873 3.01072 12.2928 4.74444 12.2928C6.47816 12.2928 7.88362 10.8873 7.88362 9.15362C7.88362 7.4199 6.47816 6.01444 4.74444 6.01444ZM0.5 9.15362C0.5 6.80948 2.4003 4.90918 4.74444 4.90918C7.08858 4.90918 8.98888 6.80948 8.98888 9.15362C8.98888 11.4978 7.08858 13.3981 4.74444 13.3981C2.4003 13.3981 0.5 11.4978 0.5 9.15362Z"
        fill="#747B8A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.2556 6.01444C15.5218 6.01444 14.1164 7.4199 14.1164 9.15362C14.1164 10.8873 15.5218 12.2928 17.2556 12.2928C18.9893 12.2928 20.3947 10.8873 20.3947 9.15362C20.3947 7.4199 18.9893 6.01444 17.2556 6.01444ZM13.0111 9.15362C13.0111 6.80948 14.9114 4.90918 17.2556 4.90918C19.5997 4.90918 21.5 6.80948 21.5 9.15362C21.5 11.4978 19.5997 13.3981 17.2556 13.3981C14.9114 13.3981 13.0111 11.4978 13.0111 9.15362Z"
        fill="#747B8A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.8992 1.45043C13.7525 1.03661 14.0595 0.602173 14.4985 0.602173H16.8599C17.566 0.602173 18.1384 1.17456 18.1384 1.88063C18.1384 2.58671 17.566 3.15909 16.8599 3.15909H16.7743C16.4691 3.15909 16.2217 2.91167 16.2217 2.60646C16.2217 2.30125 16.4691 2.05383 16.7743 2.05383H16.8599C16.9556 2.05383 17.0331 1.97629 17.0331 1.88063C17.0331 1.78498 16.9556 1.70744 16.8599 1.70744H15.163L17.7373 8.96907C17.8393 9.25674 17.6888 9.57262 17.4011 9.6746C17.1134 9.77658 16.7976 9.62605 16.6956 9.33838L14.9897 4.52646L11.4988 9.70636H3.71865C3.41344 9.70636 3.16602 9.45894 3.16602 9.15373C3.16602 8.84852 3.41344 8.60109 3.71865 8.60109H4.67363L8.31948 3.23293L7.9839 2.32274H7.12145C6.81624 2.32274 6.56882 2.07532 6.56882 1.77011C6.56882 1.4649 6.81624 1.21747 7.12145 1.21747H10.4108C10.716 1.21747 10.9635 1.4649 10.9635 1.77011C10.9635 2.07532 10.716 2.32274 10.4108 2.32274H9.16189L9.32153 2.75573H14.362L13.8992 1.45043ZM14.1054 3.86099H9.74354L11.3375 7.96806L14.1054 3.86099ZM10.3976 8.60109L8.80197 4.48974L6.0097 8.60109H10.3976Z"
        fill="#747B8A"
      />
    </svg>
  );
};

export default BicycleIcon;
