// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NewsDetailsCards {
  display: flex;
  gap: 16px;
  border-bottom: 1px solid #e4e6eb;
  padding-bottom: 10px;
}
.NewsDetailsCards__image {
  width: 96px;
  height: 96px;
  object-fit: cover;
  border-radius: 12px;
}
.NewsDetailsCards__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.NewsDetailsCards__date {
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #747b8a;
}
.NewsDetailsCards__text {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #1d2026;
}`, "",{"version":3,"sources":["webpack://./src/pages/NewsDetails/components/NewsDetailsCards.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EAEA,gCAAA;EACA,oBAAA;AAAF;AACE;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,mBAAA;AACJ;AAEE;EACE,aAAA;EACA,sBAAA;EACA,8BAAA;AAAJ;AAGE;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EAEA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AAFJ;AAKE;EACE,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EAEA,cAAA;AAJJ","sourcesContent":[".NewsDetailsCards {\n  display: flex;\n  gap: 16px;\n\n  border-bottom: 1px solid #e4e6eb;\n  padding-bottom: 10px;\n  &__image {\n    width: 96px;\n    height: 96px;\n    object-fit: cover;\n    border-radius: 12px;\n  }\n\n  &__wrapper {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n  }\n\n  &__date {\n    display: flex;\n    align-items: center;\n    column-gap: 8px;\n    //styleName: Web/Body/Details;\n    font-family: Mulish;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 20px;\n    text-align: left;\n    color: #747b8a;\n  }\n\n  &__text {\n    font-family: Mulish;\n    font-size: 14px;\n    font-weight: 600;\n    line-height: 20px;\n    text-align: left;\n\n    color: #1d2026;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
