import { backend } from '../index';

export const getProducts = async data => {
  try {
    const response = await backend.get('/products/', { params: data });
    return response;
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
};

export const getProductsById = async id => {
  try {
    const response = await backend.get(`/products/${id}/`);
    return response;
  } catch (error) {
    console.error('Error fetching products by id:', error);
    throw error;
  }
};
