import React from 'react';

const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.7462 3.89795C18.7468 3.89812 18.7473 3.89837 18.7482 3.89933L20.1003 5.25144C20.1013 5.2524 20.1015 5.25285 20.1017 5.25341C20.1019 5.25394 20.1019 5.2545 20.1017 5.25503C20.1015 5.25557 20.1013 5.25601 20.1003 5.25697L13.3573 12L20.1003 18.7431C20.1013 18.744 20.1015 18.7445 20.1017 18.745C20.1019 18.7455 20.1019 18.7461 20.1017 18.7466C20.1015 18.7472 20.1013 18.7476 20.1003 18.7486L18.7482 20.1007C18.7473 20.1017 18.7468 20.1019 18.7462 20.1021C18.7457 20.1022 18.7452 20.1022 18.7446 20.1021C18.7441 20.1019 18.7436 20.1017 18.7427 20.1007L11.9996 13.3577L5.25661 20.1007C5.25567 20.1017 5.2552 20.1019 5.25466 20.1021C5.25413 20.1022 5.25356 20.1022 5.25302 20.1021C5.25251 20.1019 5.25204 20.1017 5.25108 20.1007L3.89897 18.7486C3.89801 18.7476 3.89775 18.7472 3.89759 18.7466C3.89742 18.7461 3.89742 18.7455 3.89759 18.745C3.89775 18.7445 3.89801 18.744 3.89897 18.7431L10.642 12L3.89897 5.25697C3.89801 5.25604 3.89775 5.25557 3.89759 5.25503C3.89742 5.2545 3.89742 5.25392 3.89759 5.25339C3.89775 5.25287 3.89801 5.2524 3.89897 5.25144L5.25108 3.89933C5.25204 3.89837 5.25248 3.89812 5.25305 3.89795C5.25357 3.89779 5.25414 3.89779 5.25466 3.89795C5.2552 3.89812 5.25565 3.89837 5.25661 3.89933L11.9996 10.6423L18.7427 3.89933C18.7436 3.89837 18.7441 3.89812 18.7446 3.89795C18.7452 3.89779 18.7457 3.89779 18.7463 3.89795H18.7462Z"
        fill="#747B8A"
      />
    </svg>
  );
};

export default CloseIcon;
