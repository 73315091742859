// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Navbar {
  padding: 12px 200px 12px 200px;
  background: #f5f6f7;
}
.Navbar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Navbar__links {
  display: flex;
}
.Navbar__links-item {
  padding: 8px 16px;
  font-family: Mulish, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #4e5666;
  border-radius: 8px;
  transition: 0.3s all;
}
.Navbar__links-item.active {
  background: #fff;
}
.Navbar__links-item.active .h6 {
  display: flex;
  align-items: center;
  gap: 8px;
}
.Navbar__links-item.active .h6 .dot {
  width: 6px;
  height: 6px;
  background: rgb(0, 124, 110);
  border-radius: 50%;
  animation: dotAnimation 3s infinite;
}
.Navbar__buttons {
  display: flex;
  column-gap: 8px;
}
.Navbar__buttons .logoutButton p {
  display: flex;
  align-items: center;
  gap: 8px;
}

@keyframes dotAnimation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/shared/Header/components/Navbar/Navbar.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,mBAAA;AACF;AAAE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAEJ;AACE;EACE,aAAA;AACJ;AACI;EACE,iBAAA;EACA,+BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;EACA,oBAAA;AACN;AACM;EACE,gBAAA;AACR;AACQ;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AACV;AACU;EACE,UAAA;EACA,WAAA;EACA,4BAAA;EACA,kBAAA;EACA,mCAAA;AACZ;AAME;EACE,aAAA;EACA,eAAA;AAJJ;AAOM;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AALR;;AAWA;EACE;IACE,UAAA;EARF;EAUA;IACE,UAAA;EARF;EAUA;IACE,UAAA;EARF;AACF","sourcesContent":[".Navbar {\n  padding: 12px 200px 12px 200px;\n  background: #f5f6f7;\n  .container {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n\n  &__links {\n    display: flex;\n\n    &-item {\n      padding: 8px 16px;\n      font-family: Mulish, sans-serif;\n      font-size: 14px;\n      font-weight: 400;\n      line-height: 20px;\n      text-align: left;\n      color: #4e5666;\n      border-radius: 8px;\n      transition: 0.3s all;\n\n      &.active {\n        background: #fff;\n\n        .h6 {\n          display: flex;\n          align-items: center;\n          gap: 8px;\n\n          .dot {\n            width: 6px;\n            height: 6px;\n            background: rgb(0, 124, 110);\n            border-radius: 50%;\n            animation: dotAnimation 3s infinite;\n          }\n        }\n      }\n    }\n  }\n\n  &__buttons {\n    display: flex;\n    column-gap: 8px;\n\n    .logoutButton {\n      p {\n        display: flex;\n        align-items: center;\n        gap: 8px;\n      }\n    }\n  }\n}\n\n@keyframes dotAnimation {\n  0% {\n    opacity: 0;\n  }\n  50% {\n    opacity: 1;\n  }\n  100% {\n    opacity: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
