// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Breadcrumbs__list {
  display: flex;
  align-items: center;
  list-style: none;
  gap: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/Breadcrumbs/Breadcrumbs.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,QAAA;AAAJ","sourcesContent":[".Breadcrumbs {\n  &__list {\n    display: flex;\n    align-items: center;\n    list-style: none;\n    gap: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
