// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StyledTextField .MuiOutlinedInput-root {
  background: #fff;
  color: #747b8a;
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  border-radius: 8px;
  border: none;
  padding: 8px 24px 8px 16px;
  outline: none;
}
.StyledTextField .MuiOutlinedInput-root.Mui-focused {
  border: none;
}
.StyledTextField .MuiOutlinedInput-input {
  padding: 0;
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/StyledTextField/StyledTextField.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,cAAA;EACA,iCAAA;EACA,eAAA;EACA,kBAAA;EACA,YAAA;EACA,0BAAA;EACA,aAAA;AAAJ;AACI;EACE,YAAA;AACN;AAEE;EACE,UAAA;EACA,aAAA;AAAJ","sourcesContent":[".StyledTextField {\n  .MuiOutlinedInput-root {\n    background: #fff;\n    color: #747b8a;\n    font-family: 'Mulish', sans-serif;\n    font-size: 14px;\n    border-radius: 8px;\n    border: none;\n    padding: 8px 24px 8px 16px;\n    outline: none;\n    &.Mui-focused {\n      border: none;\n    }\n  }\n  .MuiOutlinedInput-input {\n    padding: 0;\n    outline: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
