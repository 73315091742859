import React from 'react';
import { Link } from 'react-router-dom';
import './Breadcrumbs.scss';
import { CaptionsText } from '../Text/Body/Bodies';
import { ArrowRight2 } from 'iconsax-react';

const Breadcrumbs = ({ items }) => {
  return (
    <nav aria-label="breadcrumb" className="Breadcrumbs container">
      <ol className="Breadcrumbs__list">
        {items.map((item, index) => {
          const isLast = index === items.length - 1;

          return (
            <React.Fragment key={item.path}>
              <li className="Breadcrumbs__list-item">
                {!isLast ? (
                  <Link to={item.path}>
                    <CaptionsText variant={'dark540'}>{item.name}</CaptionsText>
                  </Link>
                ) : (
                  <CaptionsText variant={'dark540'}>{item.name}</CaptionsText>
                )}
              </li>
              {!isLast && <ArrowRight2 size="16" color="#747B8A" />}
            </React.Fragment>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
