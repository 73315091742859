// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PopularDecisionsPage {
  margin-top: 24px;
}
.PopularDecisionsPage__filters {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e4e6eb;
  margin-top: 15px;
  padding-top: 15px;
}
.PopularDecisionsPage__filters__options {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.PopularDecisionsPage__filters__options__text {
  font-family: Mulish;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  color: #1d2026;
}
.PopularDecisionsPage__filters__fields {
  display: flex;
  column-gap: 16px;
}
.PopularDecisionsPage__filters__fields__item {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.PopularDecisionsPage__filters__fields__item__text {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #1d2026;
}
.PopularDecisionsPage__cards {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 24px;
  margin-bottom: 24px;
}`, "",{"version":3,"sources":["webpack://./src/pages/PopularDecisionsPage/PopularDecisionsPage.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAAE;EACE,aAAA;EACA,8BAAA;EACA,6BAAA;EACA,gBAAA;EACA,iBAAA;AAEJ;AAAI;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;AAEN;AAAM;EACE,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AAER;AAEI;EACE,aAAA;EACA,gBAAA;AAAN;AACM;EACE,aAAA;EACA,sBAAA;EACA,aAAA;AACR;AAAQ;EACE,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AAEV;AAIE;EACE,aAAA;EACA,eAAA;EACA,SAAA;EACA,gBAAA;EACA,mBAAA;AAFJ","sourcesContent":[".PopularDecisionsPage {\n  margin-top: 24px;\n  &__filters {\n    display: flex;\n    justify-content: space-between;\n    border-top: 1px solid #e4e6eb;\n    margin-top: 15px;\n    padding-top: 15px;\n\n    &__options {\n      display: flex;\n      align-items: center;\n      column-gap: 10px;\n\n      &__text {\n        font-family: Mulish;\n        font-size: 20px;\n        font-weight: 700;\n        line-height: 28px;\n        text-align: left;\n        color: #1d2026;\n      }\n    }\n\n    &__fields {\n      display: flex;\n      column-gap: 16px;\n      &__item {\n        display: flex;\n        flex-direction: column;\n        row-gap: 10px;\n        &__text {\n          font-family: Mulish;\n          font-size: 16px;\n          font-weight: 600;\n          line-height: 24px;\n          text-align: left;\n          color: #1d2026;\n        }\n      }\n    }\n  }\n\n  &__cards {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 24px;\n    margin-top: 24px;\n    margin-bottom: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
