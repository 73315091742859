import React from 'react';
import './InputField.scss';
import { makeStyles } from '@mui/styles';
import InputMask from 'react-input-mask';
import { Calendar, Call, Sms } from 'iconsax-react';
import { InputAdornment, Skeleton, TextField } from '@mui/material';
import { ErrorMessage } from 'formik';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiFilledInput-root': {
      backgroundColor: '#F5F6F7',
      borderRadius: 12,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#F5F6F7',
      height: '100%',
      '&:hover': {
        backgroundColor: '#EDEFF2',
      },
      '& input::placeholder': {
        color: '#4E5666',
      },
      '&.Mui-focused': {
        borderColor: '#007C6E',
        borderWidth: 1,
        borderStyle: 'solid',
        backgroundColor: '#F5F6F7',
      },
    },
    '& .MuiInputLabel-root': {
      color: '#4E5666',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '24px',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#4E5666',
    },
    '& .MuiFilledInput-underline:before': {
      borderBottom: 'none',
      display: 'none',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: 'none',
      display: 'none',
    },
  },
  errorText: {
    color: 'red',
    fontSize: '12px',
    marginTop: '4px',
  },
}));

const InputField = ({
  field,
  form: { touched, errors },
  inputLabel,
  label,
  placeholder,
  inputType,
  height = 'auto',
  loading,
}) => {
  const classes = useStyles();
  const hasError = touched[field.name] && errors[field.name];

  const renderInput = inputProps =>
    loading ? (
      <Skeleton
        variant={'rounded'}
        sx={{
          maxWidth: '356px',
          width: '100%',
          height: height === 'auto' ? '56px' : height,
        }}
      ></Skeleton>
    ) : (
      <TextField
        {...inputProps} // Передаем значения из Formik: value, onChange, onBlur
        label={inputLabel}
        hiddenLabel={inputLabel ? false : true}
        fullWidth
        sx={{ height: height || 'auto' }}
        variant="filled"
        placeholder={placeholder || 'Placeholder'}
        className={classes.root}
        error={Boolean(hasError)} // Добавляем ошибку, если есть
        helperText={hasError ? errors[field.name] : ''} // Выводим сообщение об ошибке
        InputLabelProps={{
          shrink: Boolean(field.value) || undefined,
        }}
        InputProps={
          inputType === 'phone'
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <Call />
                  </InputAdornment>
                ),
              }
            : inputType === 'email'
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <Sms />
                    </InputAdornment>
                  ),
                }
              : inputType === 'date'
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <Calendar />
                      </InputAdornment>
                    ),
                  }
                : null
        }
      />
    );

  return (
    <div className="InputField">
      <p className="InputField__label">{label}</p>
      {inputType === 'date' ? (
        <InputMask
          mask="99/99/9999"
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
        >
          {inputProps => renderInput(inputProps)}
        </InputMask>
      ) : (
        renderInput(field)
      )}
    </div>
  );
};

export default InputField;
