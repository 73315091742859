// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header {
  width: 100%;
  padding: 12px 200px;
  background: #1d2026;
  display: flex;
  justify-content: space-between;
}
.Header__leftSide {
  display: flex;
  column-gap: 32px;
}
.Header__contacts {
  display: flex;
  font-family: "Mulish", sans-serif;
  color: #fff;
  column-gap: 16px;
}
.Header__contacts__row {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}
.Header__contacts__row-text {
  display: flex;
  column-gap: 4px;
}
.Header__contacts__row-link {
  color: #fff;
}
.Header__rightSide {
  display: flex;
  column-gap: 32px;
  align-items: center;
}
.Header__social {
  display: flex;
  column-gap: 16px;
}
.Header__field {
  width: 150px;
}`, "",{"version":3,"sources":["webpack://./src/shared/Header/Header.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,mBAAA;EACA,mBAAA;EACA,aAAA;EACA,8BAAA;AACF;AACE;EACE,aAAA;EACA,gBAAA;AACJ;AAEE;EACE,aAAA;EACA,iCAAA;EACA,WAAA;EACA,gBAAA;AAAJ;AACI;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AACN;AAAM;EACE,aAAA;EACA,eAAA;AAER;AAAM;EACE,WAAA;AAER;AAGE;EACE,aAAA;EACA,gBAAA;EACA,mBAAA;AADJ;AAIE;EACE,aAAA;EACA,gBAAA;AAFJ;AAKE;EACE,YAAA;AAHJ","sourcesContent":[".Header {\n  width: 100%;\n  padding: 12px 200px;\n  background: #1d2026;\n  display: flex;\n  justify-content: space-between;\n\n  &__leftSide {\n    display: flex;\n    column-gap: 32px;\n  }\n\n  &__contacts {\n    display: flex;\n    font-family: 'Mulish', sans-serif;\n    color: #fff;\n    column-gap: 16px;\n    &__row {\n      display: flex;\n      flex-direction: column;\n      row-gap: 4px;\n      &-text {\n        display: flex;\n        column-gap: 4px;\n      }\n      &-link {\n        color: #fff;\n      }\n    }\n  }\n\n  &__rightSide {\n    display: flex;\n    column-gap: 32px;\n    align-items: center;\n  }\n\n  &__social {\n    display: flex;\n    column-gap: 16px;\n  }\n\n  &__field {\n    width: 150px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
