// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.Tours {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.Tours__header {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.Tours__header-title {
  padding-bottom: 8px;
  border-bottom: 1px solid #e4e6eb;
}
.Tours__header-filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Tours__header-filters__text {
  display: flex;
  align-items: center;
  gap: 16px;
}
.Tours__header-filters__form-selectors {
  display: flex;
  align-items: center;
  gap: 24px;
}
.Tours__header-filters__form-selectors .InputDrop, .Tours__header-filters__form-selectors .DateField {
  width: 200px;
}
.Tours__cards {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
}
.Tours__cards .DetailsText {
  display: -webkit-box; /* Используем flexbox контейнер с отображением в виде коробки */
  -webkit-box-orient: vertical; /* Указываем вертикальную ориентацию */
  overflow: hidden; /* Скрываем текст, выходящий за пределы контейнера */
  text-overflow: ellipsis; /* Добавляем троеточие в конце */
  -webkit-line-clamp: 4; /* Ограничиваем количество строк до 3 */
}`, "",{"version":3,"sources":["webpack://./src/components/Tours/Tours.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAEF;AAAE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAEJ;AADI;EACE,mBAAA;EACA,gCAAA;AAGN;AADI;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAGN;AAFM;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAIR;AAAQ;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAEV;AAAU;EACE,YAAA;AAEZ;AAKE;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,SAAA;AAHJ;AAKI;EACE,oBAAA,EAAA,+DAAA;EACA,4BAAA,EAAA,sCAAA;EACA,gBAAA,EAAA,oDAAA;EACA,uBAAA,EAAA,gCAAA;EACA,qBAAA,EAAA,uCAAA;AAHN","sourcesContent":[".Tours {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n\n  &__header {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n    &-title {\n      padding-bottom: 8px;\n      border-bottom: 1px solid #e4e6eb;\n    }\n    &-filters {\n      display: flex;\n      align-items: center;\n      justify-content: space-between;\n      &__text {\n        display: flex;\n        align-items: center;\n        gap: 16px;\n      }\n\n      &__form {\n        &-selectors {\n          display: flex;\n          align-items: center;\n          gap: 24px;\n\n          .InputDrop, .DateField {\n            width: 200px;\n          }\n        }\n      }\n    }\n  }\n\n  &__cards {\n    display: flex;\n    align-items: center;\n    flex-wrap: wrap;\n    gap: 24px;\n\n    .DetailsText {\n      display: -webkit-box; /* Используем flexbox контейнер с отображением в виде коробки */\n      -webkit-box-orient: vertical; /* Указываем вертикальную ориентацию */\n      overflow: hidden; /* Скрываем текст, выходящий за пределы контейнера */\n      text-overflow: ellipsis; /* Добавляем троеточие в конце */\n      -webkit-line-clamp: 4; /* Ограничиваем количество строк до 3 */\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
