import React from 'react';

import './FilledButton.scss'

const FilledButton = ({text, action, type}) => {
    return (
        <button className={'filled-button'} onClick={action} type={type}>{text}</button>
    );
};

export default FilledButton;