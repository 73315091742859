import { backend } from '../index';

export const getClothingSizes = async data => {
  try {
    const response = await backend.get('/clothing-sizes/', { params: data });
    return response;
  } catch (error) {
    console.error('Error fetching clothing sizes:', error); 
    throw error;
  }
};
