// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Headline {
  font-weight: 600;
  letter-spacing: 0;
  word-spacing: normal;
}
.Headline.h1 {
  font-size: 44px;
  line-height: 56px;
}
.Headline.h2 {
  font-size: 32px;
  line-height: 44px;
}
.Headline.h3 {
  font-size: 24px;
  line-height: 32px;
}
.Headline.h4 {
  font-size: 20px;
  line-height: 28px;
}
.Headline.h5 {
  font-size: 16px;
  line-height: 24px;
}
.Headline.h6 {
  font-size: 14px;
  line-height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Text/Headlines/Headlines.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,iBAAA;EACA,oBAAA;AACF;AACE;EACE,eAAA;EACA,iBAAA;AACJ;AAEE;EACE,eAAA;EACA,iBAAA;AAAJ;AAGE;EACE,eAAA;EACA,iBAAA;AADJ;AAIE;EACE,eAAA;EACA,iBAAA;AAFJ;AAKE;EACE,eAAA;EACA,iBAAA;AAHJ;AAME;EACE,eAAA;EACA,iBAAA;AAJJ","sourcesContent":[".Headline {\n  font-weight: 600;\n  letter-spacing: 0;\n  word-spacing: normal;\n\n  &.h1 {\n    font-size: 44px;\n    line-height: 56px;\n  }\n\n  &.h2 {\n    font-size: 32px;\n    line-height: 44px;\n  }\n\n  &.h3 {\n    font-size: 24px;\n    line-height: 32px;\n  }\n\n  &.h4 {\n    font-size: 20px;\n    line-height: 28px;\n  }\n\n  &.h5 {\n    font-size: 16px;\n    line-height: 24px;\n  }\n\n  &.h6 {\n    font-size: 14px;\n    line-height: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
