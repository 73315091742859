import React from 'react';

import './WhatSayAboutUsCard.scss';
import { Headline4 } from '../../../../components/Text/Headlines/Headlines';

import CalendarIcon from '../../../../assets/icons/calendar.svg';
import RatingIcon from '../../../../assets/icons/magic-star.svg';
import { DetailsText, MainText } from '../../../../components/Text/Body/Bodies';

const WhatSayAboutUsCard = ({ date, comment, customer, rating }) => {
  return (
    <div className="WhatSayAboutUsCard">
      <DetailsText variant={'dark540'}>
        <img src={CalendarIcon} alt="" />
        {date}
      </DetailsText>

      <div className="WhatSayAboutUsCard__content">
        <MainText variant={'dark740'}>{comment}</MainText>

        <div className="WhatSayAboutUsCard__content-rating">
          <Headline4>{customer}</Headline4>

          <MainText variant={'dark900'}>
            <img src={RatingIcon} alt="" />
            {rating}
          </MainText>
        </div>
      </div>
    </div>
  );
};

export default WhatSayAboutUsCard;
