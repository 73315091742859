import { atom } from 'recoil';

export const loginModal = atom({
  key: 'loginModal',
  default: false,
});

export const RegistrationModal = atom({
  key: 'registerModal',
  default: false,
});

export const ForgotPasswordModal = atom({
  key: 'forgotPasswordModal',
  default: false,
});
