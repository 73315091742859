// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filled-button {
  padding: 16px 32px;
  border-radius: 8px;
  background: #007c6e;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Buttons/FilledButton/FilledButton.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,kBAAA;EACA,mBAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,YAAA;AACF","sourcesContent":[".filled-button {\n  padding: 16px 32px;\n  border-radius: 8px;\n  background: #007c6e;\n  color: #fff;\n  font-size: 18px;\n  font-weight: 500;\n  line-height: 24px;\n  text-align: left;\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
