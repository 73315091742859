import {
  Headline1,
  Headline2,
} from '../../components/Text/Headlines/Headlines';
import 'swiper/css';
import './DestinationDetail.scss';
import { Element } from 'react-scroll';
import { Skeleton } from '@mui/material';
import { Navigation } from 'swiper/modules';
import { useParams } from 'react-router-dom';
import Tours from '../../components/Tours/Tours';
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useEffect, useState } from 'react';
import { getBlogs } from '../../api/Blogs/Blogs.api';
import { getAllTourTypes } from '../../api/Tours/Tours.api';
import { ImportantText } from '../../components/Text/Body/Bodies';
import { getCountryById } from '../../api/Countries/Countries.api';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import OurBlogCard from '../Main/components/OurBlog/components/OurBlogCard/OurBlogCard';

const DestinationDetail = () => {
  const { id } = useParams();
  const [blogs, setBlogs] = useState([]);
  const [tours, setTours] = useState([]);
  const [country, setCountry] = useState({});
  const [loading, setLoading] = useState(true);

  const getCountry = async () => {
    const response = await getCountryById(id);
    setCountry(response.data);
  };

  const getAllTours = async () => {
    const response = await getAllTourTypes({ country__id: id });
    setTours(response.results);
  };

  const getAllBlogs = async () => {
    const response = await getBlogs({ country__id: id });
    setBlogs(response.data.results);
  };

  const fetchData = async () => {
    await Promise.all([getCountry(), getAllTours(), getAllBlogs()]);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="DestinationDetail">
      <Element
        className="DestinationDetail__banner"
        name="Destination Detail Banner"
      >
        {loading ? (
          <Skeleton width={'100%'} height={401}></Skeleton>
        ) : (
          <>
            <img src={country.icon} />
            <Headline1 variant={'white'}>{country.name}</Headline1>
          </>
        )}
      </Element>
      <Element
        className="DestinationDetail__breadcrumbs container"
        name="Destination Detail Breadcrumbs"
      >
        {loading ? (
          <Skeleton variant="rounded" width={'35%'}></Skeleton>
        ) : (
          <Breadcrumbs
            items={[
              { path: '/', name: 'Home' },
              { path: '/destinations', name: 'Destinations' },
              { path: `/destinations/${country?.id}`, name: country.name },
            ]}
          />
        )}
      </Element>
      <Element
        className=" DestinationDetail__info"
        name="Destination Detail Info"
      >
        <div className="DestinationDetail__info-about container">
          <div className="DestinationDetail__info-about__text">
            {loading ? (
              <Skeleton
                variant="text"
                sx={{ fontSize: '32px', width: 430 }}
              ></Skeleton>
            ) : (
              <Headline2 variant={'dark900'}>About {country.name}</Headline2>
            )}
            {loading ? (
              <Skeleton
                variant="rectangular"
                width={588}
                height={156}
              ></Skeleton>
            ) : (
              <ImportantText variant={'dark720'}>
                {country?.description}
              </ImportantText>
            )}
          </div>
          {loading ? (
            <Skeleton variant="rounded" width={486} height={216}></Skeleton>
          ) : (
            <iframe
              width="486"
              height="216"
              src={country.url}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          )}
        </div>
        <Tours loading={loading} country={country} tours={tours} />
        {blogs.length > 0 && (
          <div className="DestinationDetail__blogs container">
            <div className="DestinationDetail__blogs-controlls">
              <Headline2 variant={'black'}>{country?.name} blog</Headline2>
              {blogs?.length > 4 && (
                <div className="DestinationDetail__blogs-controlls__swiper">
                  <div className="DestinationDetail__blogs-controlls__swiper-prev">
                    <KeyboardArrowLeftIcon />
                  </div>
                  <div className="DestinationDetail__blogs-controlls__swiper-next">
                    <KeyboardArrowRightIcon />
                  </div>
                </div>
              )}
            </div>
            <Swiper
              spaceBetween={24}
              slidesPerView={4}
              loop={true}
              modules={[Navigation]}
              navigation={{
                prevEl: '.DestinationDetail__blogs-controlls__swiper-prev',
                nextEl: '.DestinationDetail__blogs-controlls__swiper-next',
              }}
            >
              {blogs?.map((blog, index) => (
                <SwiperSlide key={index}>
                  <OurBlogCard
                    key={blog.id}
                    image={blog?.icon}
                    title={blog?.title}
                    description={blog?.text}
                    date={blog?.created_at}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
        <div className="DestinationDetail__gallery">
          <div className="container">
            <div className="DestinationDetail__gallery-controlls">
              <Headline2 variant={'dark900'}>Gallery</Headline2>
              {country?.country_images?.length > 4 && (
                <div className="DestinationDetail__gallery-controlls__swiperControlls">
                  <div className="DestinationDetail__gallery-controlls__swiperControlls-prev">
                    <KeyboardArrowLeftIcon />
                  </div>
                  <div className="DestinationDetail__gallery-controlls__swiperControlls-next">
                    <KeyboardArrowRightIcon />
                  </div>
                </div>
              )}
            </div>
          </div>
          <Swiper
            slidesPerView={4}
            spaceBetween={40}
            loop={true}
            centeredSlides={true}
            modules={[Navigation]}
            className="DestinationDetail__gallery-swiper"
            navigation={{
              prevEl:
                '.DestinationDetail__gallery-controlls__swiperControlls-prev',
              nextEl:
                '.DestinationDetail__gallery-controlls__swiperControlls-next',
            }}
            onSlideChange={() => console.log('slide change')}
            onSwiper={swiper => console.log(swiper)}
          >
            {country?.country_images?.map((galleryImage, idx) => (
              <SwiperSlide
                key={idx}
                className="DestinationDetail__gallery-swiper__slide"
              >
                <img src={galleryImage.image} alt={`Image ${idx}`} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </Element>
    </div>
  );
};

export default DestinationDetail;
