import React from 'react';

import './NewsDetails.scss';
import {
  Headline1,
  Headline4,
} from '../../components/Text/Headlines/Headlines';
import Calendar from '../../assets/icons/calendar.svg';

import NewsDetailsImage from '../../assets/images/NewsDetails/DetailsImage.jpeg';
import SmallNewsDetailsImage from '../../assets/images/NewsDetails/SmallDetailsImage.jpeg';
import NewsDetailsCards from './components/NewsDetailsCards';

import NewsDetailsCardImg from '../../assets/images/NewsDetails/NewsDetailsCardImg.jpeg';

const NewsDetails = () => {
  return (
    <div className="NewsDetails">
      <div className="container">
        <p className="NewsDetails__leftSide__date">
          <img src={Calendar} alt="" /> 10 may 2023
        </p>
        <div className="NewsDetails__content">
          <div className="NewsDetails__leftSide">
            <Headline1>Khiva: The Silk Road city most tourists miss</Headline1>
            <img
              src={NewsDetailsImage}
              className="NewsDetails__leftSide__image"
              alt=""
            />
            <p className="NewsDetails__leftSide__descriptionBlack">
              Once an important oasis welcomed by weary travellers – and equally
              feared for its reputation for ruthless slave trading – Khiva is
              undeservedly overshadowed by Bukhara and Samarkand.
            </p>
            <p className="NewsDetails__leftSide__description">
              n the small town of Khiva in Uzbekistan, bounded by the Karakum
              and Kyzylkum deserts, all roads lead to a walled fortress known
              as Itchan Kala. That's because everything that's worth seeing and
              experiencing in Khiva is contained within the walls of this
              citadel. It's home to more than 60 cultural sites, medieval
              mosques and opulent palaces, as well as numerous museums, souvenir
              shops and craft studios
            </p>
            <p className="NewsDetails__leftSide__description">
              The Itchan Kala is where I was headed bright and early one autumn
              morning. Entering from the Ota Darwza, or the West Gate, located
              close to the ticket counter, I could see the street shops slowly
              coming to life: the chugirma vendor was fluffing up his collection
              of sheepskin fur hats used by locals to keep their heads warm in
              the bitter winters; the woman selling traditional
              ikat-print chapans (long coats) lured me with a striking
              black-and-white jacket; the master woodcarver was sitting on his
              chair, head bent over what looked like an intricately chiselled
              cheeseboard; the carpet weaver briefly looked up from the loom to
              smile at me before bending her head back to study the pattern.
            </p>
            <img
              src={SmallNewsDetailsImage}
              className="NewsDetails__leftSide__imageSmall"
              alt=""
            />
            <p className="NewsDetails__leftSide__description">
              hiva was once an important stop for merchants and traders on the
              Silk Road (Credit: Tuul & Bruno Morandi/Getty Images)
            </p>{' '}
            <p className="NewsDetails__leftSide__description">
              There is archaeological evidence to show that Khiva has existed
              since the 6th Century CE. It became a significant trading post on
              the Silk Road and rose to even more prominence within the region
              in the 1600s when it became the capital of the Khanate (kingdom of
              the Khan rulers).
            </p>
            <p className="NewsDetails__leftSide__paragraph">
              There is archaeological evidence to show that Khiva has existed
              since the 6th Century CE. It became a significant trading post on
              the Silk Road and rose to even more prominence within the region
              in the 1600s when it became the capital of the Khanate (kingdom of
              the Khan rulers).
            </p>
            <p className="NewsDetails__leftSide__description">
              There is archaeological evidence to show that Khiva has existed
              since the 6th Century CE. It became a significant trading post on
              the Silk Road and rose to even more prominence within the region
              in the 1600s when it became the capital of the Khanate (kingdom of
              the Khan rulers).
            </p>
          </div>
          <div className="NewsDetails__rightSide">
            <Headline4>You might like:</Headline4>
            <div className="NewsDetails__rightSide__cards">
              <NewsDetailsCards
                image={NewsDetailsCardImg}
                date={'10 may 2023'}
                text={"Why India's wildly remote islands are trending"}
              />{' '}
              <NewsDetailsCards
                image={NewsDetailsCardImg}
                date={'10 may 2023'}
                text={"Why India's wildly remote islands are trending"}
              />{' '}
              <NewsDetailsCards
                image={NewsDetailsCardImg}
                date={'10 may 2023'}
                text={"Why India's wildly remote islands are trending"}
              />{' '}
              <NewsDetailsCards
                image={NewsDetailsCardImg}
                date={'10 may 2023'}
                text={"Why India's wildly remote islands are trending"}
              />{' '}
              <p className="NewsDetails__rightSide__cards__seeAll">See all</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsDetails;
