import React from 'react';
import { Link } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { ButtonLText, ButtonMText, ButtonSText } from '../Text/Body/Bodies';
import { colors } from '../Text/Colors/Colors';
import './Buttons.scss';

export const ButtonL = ({
  to,
  children,
  variant,
  type,
  iconLeftShow,
  iconRightShow,
  disabled,
  fullWidth,
  action,
  onClick,
}) => {
  const button = (
    <button
      disabled={disabled}
      type={action || 'button'}
      onClick={onClick}
      className={`ButtonL ${type === 'tertiary' ? 'tertiary' : type === 'outlined' ? 'outlined' : 'primary'} ${iconLeftShow ? 'iconLeft' : ''} ${iconRightShow ? 'iconRight' : ''} ${disabled ? 'disabled' : ''}`}
      style={{ maxWidth: fullWidth && '100%', width: fullWidth && '100%' }}
    >
      {iconLeftShow &&
        // Проверяем, передан ли компонент иконки, если нет, используем иконку по умолчанию
        (React.isValidElement(iconLeftShow) ? (
          React.cloneElement(iconLeftShow, {
            sx: { color: colors[variant] || colors.white },
          })
        ) : (
          <KeyboardBackspaceIcon
            sx={{ color: colors[variant] || colors.white }}
          />
        ))}

      <ButtonLText variant={variant}>{children}</ButtonLText>
      {iconRightShow &&
        // Проверяем, передан ли компонент иконки, если нет, используем иконку по умолчанию
        (React.isValidElement(iconRightShow) ? (
          React.cloneElement(iconRightShow, {
            sx: { color: colors[variant] || colors.white },
          })
        ) : (
          <KeyboardBackspaceIcon
            sx={{ rotate: '180deg', color: colors[variant] || colors.white }}
          />
        ))}
    </button>
  );

  return to ? <Link to={to}>{button}</Link> : button;
};

export default ButtonL;

export const ButtonM = ({
  to,
  children,
  variant,
  type,
  iconLeftShow,
  onClick,
  iconRightShow,
  disabled,
  fullWidth,
}) => {
  return (
    <Link to={to}>
      <button
        disabled={disabled}
        onClick={onClick}
        style={{ maxWidth: fullWidth && '100%', width: fullWidth && '100%' }}
        className={`ButtonM ${type === 'tertiary' ? 'tertiary' : type === 'outlined' ? 'outlined' : 'primary'} ${iconLeftShow ? 'iconLeft' : ''} ${iconRightShow ? 'iconRight' : ''} ${disabled ? 'disabled' : ''}`}
      >
        {iconLeftShow && (
          <KeyboardBackspaceIcon
            sx={{ color: colors[variant] || colors.white, fontSize: '18px' }}
          />
        )}
        <ButtonMText variant={variant}>{children}</ButtonMText>
        {iconRightShow && (
          <KeyboardBackspaceIcon
            sx={{
              rotate: '180deg',
              color: colors[variant] || colors.white,
              fontSize: '18px',
            }}
          />
        )}
      </button>
    </Link>
  );
};

export const ButtonS = ({
  to,
  children,
  variant,
  type,
  iconLeftShow,
  iconRightShow,
  disabled,
  fullWidth,
  className,
  onClick,
}) => {
  return (
    <Link to={to}>
      <button
        onClick={onClick}
        disabled={disabled}
        style={{ maxWidth: fullWidth && '100%', width: fullWidth && '100%' }}
        className={`ButtonS ${type === 'tertiary' ? 'tertiary' : type === 'outlined' ? 'outlined' : 'primary'} ${iconLeftShow ? 'iconLeft' : ''} ${iconRightShow ? 'iconRight' : ''} ${disabled ? 'disabled' : ''} ${className ?? ''}`}
      >
        {iconLeftShow && (
          <KeyboardBackspaceIcon
            sx={{ color: colors[variant] || colors.white, fontSize: '16px' }}
          />
        )}
        <ButtonSText variant={variant}>{children}</ButtonSText>
        {iconRightShow && (
          <KeyboardBackspaceIcon
            sx={{
              rotate: '180deg',
              color: colors[variant] || colors.white,
              fontSize: '16px',
            }}
          />
        )}
      </button>
    </Link>
  );
};
