// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DestinationsPage {
  padding: 0px 0 50px;
}
.DestinationsPage__flex {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.DestinationsPage__header {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.DestinationsPage__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.DestinationsPage__title-text {
  max-width: 384px;
  width: 100%;
}
.DestinationsPage__cards {
  margin-top: 32px;
  display: flex;
  align-items: center;
  gap: 24px;
}`, "",{"version":3,"sources":["webpack://./src/pages/DestinationsPage/DestinationsPage.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;AAEE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAAJ;AAGE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AADJ;AAEI;EACE,gBAAA;EACA,WAAA;AAAN;AAIE;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;AAFJ","sourcesContent":[".DestinationsPage {\n  padding: 0px 0 50px;\n\n  &__flex {\n    display: flex;\n    flex-direction: column;\n    gap: 32px;\n  }\n\n  &__header {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n  }\n\n  &__title {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    &-text {\n      max-width: 384px;\n      width: 100%;\n    }\n  }\n\n  &__cards {\n    margin-top: 32px;\n    display: flex;\n    align-items: center;\n    gap: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
