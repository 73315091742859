import React from 'react';
import { NavLink } from 'react-router-dom';
import { DetailsText } from '../../../../components/Text/Body/Bodies';
import { Headline6 } from '../../../../components/Text/Headlines/Headlines';

const NavbarItem = ({ to, children, activeChildren }) => {
  return (
    <NavLink
      className={({ isActive }) =>
        isActive ? 'Navbar__links-item active' : 'Navbar__links-item'
      }
      to={to}
    >
      {({ isActive }) =>
        !isActive ? (
          <DetailsText variant={'dark720'}>{children}</DetailsText>
        ) : (
          <Headline6 variant={'jungle500'}>
            <div className="dot"></div>
            {children}
          </Headline6>
        )
      }
    </NavLink>
  );
};

export default NavbarItem;
