import React from 'react';

import HeaderLogo from '../../assets/images/headerLogo.svg';
import SMSIcon from '../../assets/icons/sms.svg';
import CallIcon from '../../assets/icons/call.svg';

import FacebookIcon from '../../assets/icons/facebook.svg';
import InstagramIcon from '../../assets/icons/instagram.svg';
import YoutubeIcon from '../../assets/icons/youtube.svg';

import './Header.scss';
import { Link } from 'react-router-dom';
import StyledTextField from '../../components/StyledTextField/StyledTextField';
import Navbar from './components/Navbar/Navbar';

const Header = () => {
  return (
    <>
      <header className="Header">
        <div className="Header__leftSide">
          <Link to={'/'}>
            <img src={HeaderLogo} alt="Voyage logo" className="Header__logo" />
          </Link>

          <div className="Header__contacts">
            <div className="Header__contacts__row">
              <p className="Header__contacts__row-text">
                <img
                  src={SMSIcon}
                  alt="SMS Icon"
                  className="Header__contacts__row-icon"
                />
                Email: <br />{' '}
              </p>
              <a
                href="mailto: Nt.vouage@gmail.com"
                className="Header__contacts__row-link"
              >
                Nt.vouage@gmail.com
              </a>
            </div>
            <div className="Header__contacts__row">
              <p className="Header__contacts__row-text">
                <img
                  src={CallIcon}
                  alt="Call Icon"
                  className="Header__contacts__row-icon"
                />
                Phone number: <br />{' '}
              </p>
              <a
                href="callto: +7 701 533 8070"
                className="Header__contacts__row-link"
              >
                +7 *** *** ****
              </a>
            </div>
          </div>
        </div>

        <div className="Header__rightSide">
          <div className="Header__social">
            <img
              src={FacebookIcon}
              alt="Facebook icon"
              className="Header__social-item"
            />
            <img
              src={InstagramIcon}
              alt="Instagram icon"
              className="Header__social-item"
            />
            <img
              src={YoutubeIcon}
              alt="Youtube icon"
              className="Header__social-item"
            />
          </div>
          <div className="Header__field">
            <StyledTextField placeholder={'Search'} />
          </div>
        </div>
      </header>

      <Navbar />
    </>
  );
};

export default Header;
