import React, { useEffect, useRef, useState } from 'react';
import './Registration.scss';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { loginModal, RegistrationModal } from '../../store/modals/modals.store';
import { Modal } from '@mui/material';
import CloseIcon from '../../assets/icons/CloseIcon';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import FormInput from '../FormInput/FormInput';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import OTPInput from 'react-otp-input';
import { Headline4 } from '../Text/Headlines/Headlines';
import { ButtonLText, DetailsText } from '../Text/Body/Bodies';
import { ButtonL } from '../Buttons/Buttons';
import {
  checkCode,
  createPassword,
  sendCode,
} from '../../api/SignUp/SignUp.api';
import { userState } from '../../store/users/users.store';
import { toast } from 'react-toastify';
import { signIn } from '../../api/SignIn/SignIn.api';

const validationSchemas = [
  Yup.object().shape({
    firstName: Yup.string().required('Введите имя'),
    lastName: Yup.string().required('Введите фамилию'),
    email: Yup.string().email('Неправильный email').required('Введите email'),
  }),
  Yup.object().shape({
    otp: Yup.string().required('Введите код'),
  }),
  Yup.object().shape({
    password: Yup.string().required('Введите пароль'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Пароли должны совпадать')
      .required('Подтвердите пароль'),
  }),
];

const Registration = () => {
  const [RegistrationModalState, setRegistrationModalState] =
    useRecoilState(RegistrationModal);
  const setLoginModal = useSetRecoilState(loginModal);
  const swiperRef = useRef(null);
  const formikRef = useRef(null);
  const blockRef = useRef(null);
  const [otp, setOtp] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [user, setUser] = useRecoilState(userState);

  // Добавим таймер и состояние для повторной отправки кода
  const [canResend, setCanResend] = useState(false);
  const [timer, setTimer] = useState(59);

  useEffect(() => {
    if (timer > 0) {
      const intervalId = setInterval(() => {
        setTimer(prev => prev - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    } else {
      setCanResend(true); // Разрешить повторную отправку после завершения таймера
    }
  }, [timer]);

  const updatePagination = index => {
    setCurrentStep(index);
    document
      .querySelectorAll('.Registration__block-pagination__step')
      .forEach((step, stepIndex) => {
        step.classList.toggle('active', stepIndex <= index);
      });

    if (index === 1) {
      blockRef.current.classList.add('step2');
      blockRef.current.classList.remove('step3');
    } else if (index === 2) {
      blockRef.current.classList.add('step3');
      blockRef.current.classList.remove('step2');
    } else {
      blockRef.current.classList.remove('step2');
      blockRef.current.classList.remove('step3');
    }
  };

  const handleContinue = async values => {
    if (formikRef.current) {
      try {
        if (currentStep === 0) {
          const success = await sendCodeVerification(values);
          if (success) {
            swiperRef.current.slideNext();
            updatePagination(currentStep + 1);
            // Запускаем таймер только после отправки кода и перехода на второй шаг
            setTimer(59);
            setCanResend(false);
          }
        } else if (currentStep === 1) {
          const success = await validateCode(values);
          if (success) {
            swiperRef.current.slideNext();
            updatePagination(currentStep + 1);
          }
        } else if (currentStep === 2) {
          await createNewPassword(values);
        }
        formikRef.current.submitForm();
      } catch (error) {
        console.error('Ошибка при отправке данных:', error);
      }
    }
  };

  async function sendCodeVerification(values) {
    try {
      await sendCode({ email: values.email });
      setUser({ email: values.email });
      return true; // Успешно завершено
    } catch (error) {
      console.error(
        'Ошибка при отправке кода верификации:',
        error?.response?.data?.email,
      );
      toast.error(error?.response?.data?.email[0]);
      return false; // Ошибка
    }
  }

  async function resendVerificationCode(email) {
    try {
      setTimer(59); // Сброс таймера
      setCanResend(false); // Блокируем повторную отправку
      await sendCode({ email: email });
      toast.success('Код повторно отправлен на вашу почту');
    } catch (error) {
      console.error('Ошибка при повторной отправке кода:', error);
      toast.error(error?.response?.data?.email[0]);
    }
  }

  async function validateCode(values) {
    try {
      const response = await checkCode({ email: values.email, code: otp });
      localStorage.setItem('LCK', JSON.stringify(response.access));
      setUser(response);
      return true; // Успешно завершено
    } catch (error) {
      console.error('Ошибка при проверке кода:', error);
      toast.error(error?.response?.data?.detail);
      return false; // Ошибка
    }
  }

  async function createNewPassword(values) {
    await createPassword({
      password1: values.password,
      password2: values.confirmPassword,
    });

    const response = await signIn({
      username: values.email,
      password: values.password,
    });

    setUser(response?.user);

    setRegistrationModalState(false);
  }

  return (
    <Modal
      open={RegistrationModalState}
      onClose={() => setRegistrationModalState(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="Registration">
        <div className="Registration__block" ref={blockRef}>
          <div className="Registration__block-title">
            <h2>Регистрация</h2>
            <button
              className="Registration__block-close"
              onClick={() => setRegistrationModalState(false)}
            >
              <CloseIcon />
            </button>
          </div>
          <div className="Registration__block-pagination">
            <button
              className="Registration__block-pagination__step active"
              disabled
            ></button>
            <button
              className="Registration__block-pagination__step"
              disabled
            ></button>
            <button
              className="Registration__block-pagination__step"
              disabled
            ></button>
          </div>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              password: '',
              confirmPassword: '',
            }}
            validationSchema={validationSchemas[currentStep]}
            onSubmit={() => {
              console.log('Continue');
            }}
            innerRef={formikRef}
          >
            {({ isValid, dirty, handleSubmit, values }) => (
              <Form>
                <Swiper
                  slidesPerView={1}
                  autoHeight={true}
                  className="Registration__block-swiper"
                  onSlideChange={swiper => {
                    const newIndex = swiper.activeIndex;
                    if (newIndex !== currentStep) {
                      updatePagination(newIndex);
                    }
                  }}
                  allowTouchMove={false}
                  simulateTouch={false}
                  onSwiper={swiper => {
                    swiperRef.current = swiper;
                  }}
                >
                  <SwiperSlide>
                    <div className="Registration__block-swiper__form1">
                      <Field
                        name="firstName"
                        component={FormInput}
                        label="Имя"
                        inputLabel="Введите ваше имя"
                      />
                      <Field
                        name="lastName"
                        component={FormInput}
                        label="Фамилия"
                        inputLabel="Введите вашу фамилию"
                      />
                      <Field
                        name="email"
                        component={FormInput}
                        label="Email"
                        inputLabel="Введите email"
                      />
                      <div className="Registration__form-buttons">
                        <ButtonL
                          fullWidth={true}
                          disabled={!(isValid && dirty)}
                          action={'button'}
                          onClick={() => handleContinue(values)}
                        >
                          <ButtonLText variant={'white'}>
                            Продолжить
                          </ButtonLText>
                        </ButtonL>
                        <p className="Registration__form-buttons-signUp">
                          Уже есть аккаунт?
                          <span
                            onClick={() => {
                              setRegistrationModalState(false);
                              setLoginModal(true);
                            }}
                          >
                            Войти
                          </span>
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="Registration__block-swiper__form2">
                      <div className="Registration__block-swiper__form2-title">
                        <Headline4 variant={'dark900'} textAlign={'center'}>
                          Enter the code
                        </Headline4>
                        <DetailsText variant={'dark720'} textAlign={'center'}>
                          We have sent the code to your email. Please enter it.
                        </DetailsText>
                      </div>
                      <OTPInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        containerStyle={
                          'Registration__block-swiper__form2-inputs'
                        }
                        inputStyle={
                          'Registration__block-swiper__form2-inputs__input'
                        }
                        renderInput={props => <input {...props} />}
                      />
                      <div className="Registration__block-swiper__form2-controlls">
                        <ButtonL
                          fullWidth={true}
                          action={'button'}
                          onClick={() => handleContinue(values)}
                        >
                          <ButtonLText variant={'white'}>Continue</ButtonLText>
                        </ButtonL>
                        <ButtonL
                          type={'tertiary'}
                          fullWidth={true}
                          action={'button'}
                          onClick={
                            canResend
                              ? () => resendVerificationCode(values?.email)
                              : null
                          }
                          disabled={!canResend} // Отключаем кнопку до истечения таймера
                        >
                          <ButtonLText variant={'jungle500'}>
                            {canResend
                              ? 'Resend Code'
                              : `Resend after: 00:${timer < 10 ? `0${timer}` : timer}`}
                          </ButtonLText>
                        </ButtonL>
                      </div>
                      <p className="Registration__form-buttons-signUp">
                        Уже есть аккаунт?
                        <span
                          onClick={() => {
                            setRegistrationModalState(false);
                            setLoginModal(true);
                          }}
                        >
                          Войти
                        </span>
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="Registration__block-swiper__form3">
                      <Field
                        name="password"
                        component={FormInput}
                        label="Создайте пароль"
                        inputLabel="Введите пароль"
                        type="password"
                      />
                      <Field
                        name="confirmPassword"
                        component={FormInput}
                        label="Подтвердите пароль"
                        inputLabel="Повторите пароль"
                        type="password"
                      />
                      <div className="Registration__form-buttons">
                        <ButtonL
                          fullWidth={true}
                          disabled={!(isValid && dirty)}
                          action={'button'}
                          onClick={() => handleContinue(values)}
                        >
                          <ButtonLText variant={'white'}>
                            Завершить регистрацию
                          </ButtonLText>
                        </ButtonL>
                      </div>
                      <p className="Registration__form-buttons-signUp">
                        Уже есть аккаунт?<span>Войти</span>
                      </p>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default Registration;
