import { backend } from './../index';

export const getCountries = async data => {
  try {
    const response = await backend.get('/countries/', { params: data });
    return response;
  } catch (error) {
    console.error('Error fetching countries:', error);
    throw error;
  }
};

export const getDestinationsByCountries = async data => {
  try {
    const response = await backend.get('/countries/destinations/', {
      params: data,
    });
    return response;
  } catch (error) {
    console.error('Error fetching destinations by countries:', error);
    throw error;
  }
};

///countries/{id}/
export const getCountryById = async ( id) => {
  try {
    const response = await backend.get(`/countries/${id}/`);
    return response;
  } catch (error) {
    console.error('Error fetching country by ID:', error);
    throw error;
  }
};
