// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OurBlog {
  padding-bottom: 24px;
}
.OurBlog__content {
  margin-top: 24px;
  display: flex;
  column-gap: 24px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Main/components/OurBlog/OurBlog.scss"],"names":[],"mappings":"AAAA;EAOE,oBAAA;AALF;AADE;EACE,gBAAA;EACA,aAAA;EACA,gBAAA;AAGJ","sourcesContent":[".OurBlog {\n  &__content {\n    margin-top: 24px;\n    display: flex;\n    column-gap: 24px;\n  }\n\n  padding-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
